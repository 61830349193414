import { Navigate, Outlet } from "react-router-dom";
import { Header } from "../../components/Header";
import Login from "../User/login";
import React from "react";
import { Footer } from "../../components/Footer";

const WebLayout = () => {
  const user = JSON.parse(sessionStorage.getItem("currentUser"));
  return (
    <>
      {user !== null && user !== undefined ? (
        <React.Fragment>
          {/* <Header /> */}
          <Outlet />
          {/* <Footer /> */}
        </React.Fragment>
      ) : (
        <Navigate to="/login" element={<Login />} />
      )}
    </>
  );
};

export default WebLayout;
