import React, { useState } from "react";
import PropertyLinkForm from "./PropertyLinkForm";
import { useLocation } from 'react-router-dom';

const AddPropertyLink = () => {
  // const [isLoading, setIsLoading] = useState(false);
  // const location = useLocation();
  // const { url } = location.state || {};
  // const currentUrl = window.location.href;
  // // Create a URL object
  // const urlParams = new URL(currentUrl);
  // // Extract the values of redirectUrl and type
  // const redirectUrl = urlParams.searchParams.get('redirectUrl');
  // return (
  //   <>
  //     {/* {isLoading && <Spinner />} */}
  //     <div className="add-property-standard-plan">
  //       <PropertyLinkForm isLoading={isLoading} setIsLoading={setIsLoading} redirectUrl={redirectUrl || url} />
  //     </div>
  //   </>
  // );
};

export default AddPropertyLink;
