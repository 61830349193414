import * as Yup from 'yup';

export const PropertyFormValidationSchema = Yup.object().shape({
  address: Yup.string().required('Property address is required').trim(),
  type: Yup.string().required('Property type is required'),
  price: Yup.number().required('Property price is required').min(1, 'Price must be a positive value'),
  transactionType: Yup.string().required('Transaction type is required'),
  status: Yup.string().required('Property status is required'),
  furnishedStatus: Yup.string().required('Furnished status is required'),
  AIWritingCreativity: Yup.string().required('AI writing creativity level is required'),
  language: Yup.string().required('Language is required'),
  description: Yup.string().required('Property description is required').min(30, 'Min 30 characters required'),
  num_bedrooms: Yup.number().min(0, 'Must be 0 or greater').max(1000, 'Max 1000 Allowed'),
  num_baths: Yup.number().min(0, 'Must be 0 or greater').max(1000, 'Max 1000 Allowed'),
  parking: Yup.number().min(0, 'Must be 0 or greater').max(1000, 'Max 1000 Allowed'),
  sizeUnit: Yup.string().required('Size unit is required'),
  sizeValue: Yup.number().required('Size value is required').min(1, 'Size must be a positive value'),
  lotLength: Yup.number().when('sizeUnit', {
    is: 'sq feet',
    then: () => Yup.number().required('Lot length is required').min(1, 'Lot length must be a positive value'),
  }),
  lotWidth: Yup.number().when('sizeUnit', {
    is: 'sq feet',
    then: () => Yup.number().required('Lot width is required').min(1, 'Lot width must be a positive value'),
  }),
  squareLength: Yup.number().min(1, 'Square length must be a positive value'),
  squareWidth: Yup.number().min(1, 'Square width must be a positive value'),
  houseSize: Yup.number().min(1, 'Min 1 required'),
  meeting_rooms: Yup.number().min(1, 'Min 1 required'),
  conference_rooms: Yup.number().min(1, 'Min 1 required'),
  num_cabin: Yup.number().min(1, 'Min 1 required'),
});

export const initialValues = {
  name: '',
  address: '',
  type: 'Apartment',
  price: '',
  sizeValue: '',
  sizeUnit: 'acres',
  transactionType: 'for sale',
  status: 'Ready to move',
  furnishedStatus: 'Furnished',
  descriptionLength: 250,
  AIWritingCreativity: 'Neutral',
  language: 'English (US)',
  description: '',
  num_bedrooms: 0,
  num_baths: 0,
  parking: 0,
  meeting_rooms: 0,
  conference_rooms: 0,
  num_cabin: 0,
  priceSymbol: '$',
  houseSize: '',
  houseUnit: 'acres',
  lotLength: '',
  lotWidth: '',
  squareLength: '',
  squareWidth: '',
  isVideoGenerate: false,
  isPPPTGenerate: false,
};

export const propertyLinkSchema = Yup.object().shape({
  platform: Yup.string().required("Platform selection is required"),
  propertyUrl: Yup.string()
    .required("Property URL is required")
    .test("platform-specific-url", "Invalid URL for the selected platform", function (value) {
      const { platform } = this.parent
      const zillowPattern = /^https:\/\/www\.zillow\.com\//
      const realtorPattern = /^https:\/\/www\.realtor\.com\//

      if (platform === "zillow" && !zillowPattern.test(value)) {
        return this.createError({ message: "Invalid Zillow URL" })
      }
      if (platform === "realtor" && !realtorPattern.test(value)) {
        return this.createError({ message: "Invalid Realtor URL" })
      }
      return true
    }),
  type: Yup.string().required("Property type is required"),
  furnishedStatus: Yup.string().required("Furnished status is required"),
})