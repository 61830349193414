import React, { useState, useEffect } from 'react';
import AxiosRequest from '../../utils/AxiosRequest';
import { Card, ButtonGroup, Table } from 'react-bootstrap';
import { GridViewIcon, ListViewIcon } from '../../shared/NewIcon';

const VideoTemplateComponent = () => {
  const [videoTemplates, setVideoTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [viewType, setViewType] = useState('grid'); // 'grid' or 'list'
  const [filterType, setFilterType] = useState('desktop');

  useEffect(() => {
    const fetchVideoTemplates = async () => {
      try {
        const response = await AxiosRequest.get('/property/video-template');
        setVideoTemplates(response?.data?.data || []);
      } catch (error) {
        console.error('Error fetching video templates:', error);
      }
    };

    fetchVideoTemplates();
  }, []);

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  const handleVideoPlay = (templateId) => {
    setPlayingVideoId(templateId);
  };

  const filteredTemplates = videoTemplates.filter((template) => template.type === filterType);

  console.log('videoTemplates', videoTemplates);
  return (
    <div>
      <h1
        style={{
          color: '#393E41',
          fontFamily: 'Raleway',
          fontSize: '24px',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: 'normal',
        }}
      >
        Quick Templates
      </h1>

      {/* Filter and View Toggle Buttons */}
      <div className="d-flex justify-content-end align-items-center mb-3 gap-20">
        <ButtonGroup className="switch-button">
          <button
            variant=""
            className={`switch ${filterType === 'desktop' ? 'active' : ''}`}
            onClick={() => setFilterType('desktop')}
          >
            Desktop
          </button>
          <button
            variant=""
            className={`switch ${filterType === 'mobile' ? 'active' : ''}`}
            onClick={() => setFilterType('mobile')}
          >
            Mobile
          </button>
        </ButtonGroup>

        <div className="view-toggle">
          <div
            onClick={() => setViewType('grid')}
            className={`icon-wrapper ${viewType === 'grid' ? 'active' : ''}`}
            title="Grid View"
          >
            <GridViewIcon />
          </div>
          <div
            onClick={() => setViewType('list')}
            className={`icon-wrapper ${viewType === 'list' ? 'active' : ''}`}
            title="List View"
          >
            <ListViewIcon />
          </div>
        </div>
      </div>

      {/* Templates */}
      {viewType === 'grid' ? (
        <div className="row g-4">
          {filteredTemplates &&
            filteredTemplates.length > 0 &&
            filteredTemplates.map((template) => (
              <div key={template._id} className={viewType === 'grid' ? 'col-sm-12 col-md-6 col-lg-4 col-xl-3' : 'mb-3'}>
                <Card
                  onClick={() => handleTemplateSelect(template)}
                  className="cursor-pointer"
                  style={{
                    background: selectedTemplate?._id === template._id ? 'rgba(255, 107, 53, 0.10)' : 'transparent',
                    border: 0,
                  }}
                >
                  <Card.Body className="p-0">
                    <div
                      className="video-container"
                      style={{
                        position: 'relative',
                        width: '100%',
                        backgroundColor: '#000',
                        borderRadius: '15px',
                      }}
                    >
                      {playingVideoId === template._id ? (
                        <video
                          width="100%"
                          height="100%"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            borderRadius: '15px',
                          }}
                          controls
                          autoPlay
                        >
                          <source src={template.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <>
                          <img
                            src={template?.coverImageUrl}
                            alt="Video Thumbnail"
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'fill',
                              borderRadius: '15px',
                            }}
                          />

                          <button
                            className="play-button"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent card selection
                              handleVideoPlay(template._id);
                            }}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              background: 'transparent',
                              border: 'none',
                              borderRadius: '50%',
                              padding: '10px 15px',
                              color: '#fff',
                              cursor: 'pointer',
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="32" height="32">
                              <circle cx="32" cy="32" r="32" fill="#f1482d" />
                              <polygon points="25,18 25,46 46,32" fill="#fff" />
                            </svg>
                          </button>
                        </>
                      )}
                    </div>
                    <div>
                      <div
                        style={{
                          fontSize: '12px',
                          fontWeight: '600',
                          padding: '10px',
                        }}
                      >
                        {template.name}
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
        </div>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Template Name</th>
              <th>Type</th>
              <th>Preview</th>
            </tr>
          </thead>
          <tbody>
            {filteredTemplates.map((template) => (
              <tr key={template._id} onClick={() => handleTemplateSelect(template)}>
                <td>{template.name}</td>
                <td>{template.type}</td>
                <td>
                  <div
                    style={{
                      width: '100px',
                      height: '100px',
                      position: 'relative',
                      borderRadius: '10px',
                      overflow: 'hidden',
                      background: '#000',
                    }}
                  >
                    {playingVideoId === template._id ? (
                      <video
                        width="100%"
                        height="100%"
                        controls
                        autoPlay
                        style={{
                          objectFit: 'cover',
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent table row selection
                      >
                        <source src={template.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <>
                        <img
                          src={template?.coverImageUrl}
                          alt="Video Thumbnail"
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent table row selection
                            handleVideoPlay(template._id);
                          }}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            background: 'rgba(0, 0, 0, 0.5)',
                            border: 'none',
                            borderRadius: '50%',
                            padding: '5px',
                            cursor: 'pointer',
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="20" height="20">
                            <circle cx="32" cy="32" r="32" fill="#f1482d" />
                            <polygon points="25,18 25,46 46,32" fill="#fff" />
                          </svg>
                        </button>
                      </>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default VideoTemplateComponent;
