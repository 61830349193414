import { Suspense, useContext, useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import "../assets/styles/user.scss";
import "../assets/styles/common.scss";
import "../assets/styles/homepage.scss";
import "../assets/styles/app.scss";
import "../assets/styles/propertyPage.scss";
import "../assets/styles/generation.scss";
import "../assets/styles/propertyDetails.scss";
import "../assets/styles/propertyTemplate.scss";
import "../assets/styles/commercialTemplate.scss";
import "../assets/styles/responsive.scss";
import "../assets/styles/custom.scss";
import "../assets/styles/fonts.css";
import '../assets/styles/variables.scss';
import Spinner from "../shared/Spinner";
import AppRoutes from "./Approutes";
// import MessagingService from "../services/MessagingService";
import toast, { Toaster } from "react-hot-toast";
import User from "../context/UserContext";
import { TourProvider } from "../context/TourContext";
import TourSteps from "../components/TourSteps";
import { BrowserRouter } from "react-router-dom";
import MessagingService from "../services/MessagingService";

function App() {
  const { getNotifications, setPage, setHasMore, currentUser } =
    useContext(User);
  useEffect(() => {
    MessagingService.onMessage(MessagingService.messaging, async (payload) => {
      if (payload.data.type.includes("success")) {
        toast.success(payload.notification.title);
      } else if (payload.data.type.includes("error")) {
        toast.error(payload.notification.title);
      } else if (payload.data.type.includes("info")) {
        toast.success(payload.notification.title);
      } else {
        toast.error(payload.notification.title);
      }
      if (currentUser && currentUser.name) {
        try {
          setPage(1);
          setHasMore(true);
          await getNotifications();
        } catch (error) {
          console.error("Error fetching notifications", error);
        }
      }
    });
  }, [currentUser]);
  console.log("CI/CD Configured Successfully!");
  return (
    <>
      <Toaster
        toastOptions={{
          duration: 5000,
        }}
      />
      <Suspense fallback={<Spinner />}>
        <BrowserRouter>
          <TourProvider>
            <AppRoutes />
            <TourSteps />
          </TourProvider>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
