import React, { useContext, useEffect, useState } from "react";
import BannerHeader from "../../components/BannerHeader";
import PlaceholdeImage from "../../assets/images/placeholder.png";
import AxiosRequest from "../../utils/AxiosRequest";
import Spinner from "../../shared/Spinner";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTourContext } from "../../context/TourContext";
import User from "../../context/UserContext";
import { Modal, Nav } from "react-bootstrap";
import defaultAvtar from "../../assets/images/default-avtar.png";
import { AllPresentationIcon, CheckIcon, SearchIcon, UserIcon } from "../../shared/Icons";
import WorkspaceSettingPopup from '../../components/Dashboard/WorkspaceSettingPopup';

const AllWorkspace = () => {
  // const [userProperty, setUserProperty] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  // const [page, setPage] = useState(1);
  // const [totalGenerations, setTotalGenerations] = useState(null);
  // const navigate = useNavigate();
  const {
    currentUser,
    _currentUser,
    currentWorkspace,
    setCurrentWorkspace,
    handleSelectCurrentWorkspace,
    setWorkspacePopup,
    allWorkspaces,
    setAllWorkspaces,
    getAllWorkspaces,
    workspacePopup,
  } = useContext(User);

  // const [showCancelModal, setShowCancelModal] = useState(false);
  // const [type, setType] = useState('all');

  // const { setState, state } = useTourContext();

  // useEffect(() => {
  //     if (currentWorkspace) getUserProeprties()
  // }, [page, currentWorkspace, type]);

  // const getUserProeprties = async () => {
  //     try {
  //         setIsLoading(true);
  //         const result = await AxiosRequest.get(`/property?page=${page}&limit=10&workSpace=${currentWorkspace?._id}&type=${type}`);
  //         setUserProperty([...userProperty, ...result.data.data.data]);
  //         setTotalGenerations(result.data.data.totalCount);
  //         setIsLoading(false);
  //     } catch (err) {
  //         toast.error(err?.response?.data?.message || err?.message);
  //         setIsLoading(false);
  //     }
  // };

  // const handleReadMore = (data) => {
  //     navigate(`/property-details/${data._id}`);
  // };

  // useEffect(() => {
  //     if (currentUser && !currentUser?.tourTaken && state.stepIndex === 0) {
  //         setShowCancelModal(true);
  //     } else {
  //         setShowCancelModal(false);
  //     }
  // }, [currentUser]);

  // const handleTour = () => {
  //     setShowCancelModal(false);
  //     setState((prevState) => {
  //         return {
  //             ...prevState,
  //             run: true,
  //             tourActive: true,
  //         };
  //     });
  //     // setTimeout(() => {
  //     //   navigate("/my-generation")
  //     // }, 300)
  // };

  // const closeModal = async () => {
  //     await AxiosRequest.post("/user/tourCompleted");
  //     _currentUser();
  //     setShowCancelModal(false);
  // };

  // ============

  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({ search: searchTerm });

  useEffect(() => {
    const handler = setTimeout(() => setFilter((prev) => ({ ...prev, search: searchTerm })), 1000);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    getAllWorkspaces({ query: filter });
  }, [filter]);

  return (
    <>
      {allWorkspaces.loading && <Spinner />}
      <div id="generated-content" className="pricing">
        {/* <BannerHeader heading="All Workspaces" /> */}
        <div className="d-flex flex-column w-100 h-100">
          <div className="my-5">
            <div className="searchInputContainer">
              <SearchIcon className="searchIcon" />
              <input
                type="search"
                className="searchInput"
                placeholder="Search Workspace"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
          </div>
          <div className="dashboard mb-5">
            <div className="row">
              {allWorkspaces.data.length > 0 ? (
                allWorkspaces.data.map((item, index) => {
                  return (
                    <div className="col-12 mb-3" key={index} style={{ maxWidth: '430px' }}>
                      <div className="w-100 workspaceItem">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="userArea d-flex align-items-center w-100">
                            <span
                              role="button"
                              onClick={() => handleSelectCurrentWorkspace(item)}
                              className="profileImg"
                            >
                              <img src={item?.image_url || defaultAvtar} />
                            </span>
                            <div className="d-flex flex-column ms-2">
                              <span role="button" onClick={() => handleSelectCurrentWorkspace(item)} className="title">
                                {item.name}
                              </span>
                              <span role="button" onClick={() => setWorkspacePopup(item._id)} className="option">
                                Settings & Members
                              </span>
                            </div>
                          </div>
                          {item._id === currentWorkspace?._id ? (
                            <span className="me-2 active">
                              <CheckIcon />
                            </span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  {!allWorkspaces.loading && (
                    <div className="noProperty">
                      <div className="font20Blk text-center">No Workspace Found!</div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <WorkspaceSettingPopup
          workspacePopup={workspacePopup}
          setWorkspacePopup={setWorkspacePopup}
          hide={() => setWorkspacePopup(null)}
          currentUser={currentUser}
          getAllWorkspaces={getAllWorkspaces}
        />
        {/* <div className="d-flex container justify-content-center mt-5 mb-5">
                    {userProperty.length > 0 &&
                        userProperty.length < totalGenerations && (
                            <div
                                className="btnSecondary"
                                onClick={() => {
                                    setPage(page + 1);
                                }}
                            >
                                Load More
                            </div>
                        )}
                </div> */}
      </div>

      {/* <Modal
                show={showCancelModal}
                backdrop="static"
                onHide={closeModal}
                className="modalCustomNew"
            >
                <Modal.Header closeButton>Take a Tour</Modal.Header>
                <Modal.Body>
                    Take a tour to understand the flow of the website.
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btnBordered" onClick={closeModal}>
                        Skip
                    </button>
                    <button className="btn btnGreen" onClick={handleTour}>
                        Let's take a tour
                    </button>
                </Modal.Footer>
            </Modal> */}
    </>
  );
};

export default AllWorkspace;
