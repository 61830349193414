import "jspdf-autotable";
import { Breadcrumb, Col, Container, NavDropdown, Row } from "react-bootstrap";
import ContentEditable from "react-contenteditable";
import {
  AtticIcon,
  BackyardIcon,
  BasementIcon,
  BathIcon,
  BathroomIcon,
  BedRoomIcon,
  ClosetIcon,
  DiningIcon,
  DollarIcon,
  ExteriorIcon,
  GarageIcon,
  HallwayIcon,
  HomeOfficeIcon,
  HomePlanIcon,
  KitchenIcon,
  LaundryIcon,
  LivingRoomIcon,
  ParkingIcon,
  PatioIcon,
  PorchIcon,
  PropertySizeIcon,
  TranscationTypeIcon,
} from '../../shared/Icons';
// ========
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import pptxgen from 'pptxgenjs';
import toast from 'react-hot-toast';
import ContactUsImg from '../../assets/images/ContactUs.png';
import realtixLogoBlack from '../../assets/images/Logo.png';
import { processSlide } from '../../utils/helper';
import houseImg1 from './images/houseImg1.png';

const Template2 = ({
  propertyDetails,
  isEdit,
  setIsEdit,
  imageSrc,
  nearByPlaces,
  handleSaveDesc,
  editedDescription,
  handleDescriptionChange,
  generateStars,
  coordinates,
  mapContainerStyle,
  currentUser,
  setIsLoading,
  staticMapURL,
  template,
  setTemplate,
  params,
  isGenerationOwner,
  currentWorkspace,
}) => {
  const getIconByName = (name) => {
    const smallCaseName = name.toLowerCase();

    if (smallCaseName === 'exterior') {
      return <ExteriorIcon />;
    } else if (smallCaseName === 'kitchen') {
      return <KitchenIcon />;
    } else if (smallCaseName === 'living_room') {
      return <LivingRoomIcon />;
    } else if (smallCaseName === 'bedroom') {
      return <BedRoomIcon />;
    } else if (smallCaseName === 'garden') {
      return <LivingRoomIcon />;
    }
    // ================================================================
    else if (smallCaseName === 'backyard') {
      return <BackyardIcon />;
    } else if (smallCaseName === 'basement') {
      return <BasementIcon />;
    } else if (smallCaseName === 'bathroom') {
      return <BathroomIcon />;
    } else if (smallCaseName === 'dining_room') {
      return <DiningIcon />;
    } else if (smallCaseName === 'home_office') {
      return <HomeOfficeIcon />;
    } else if (smallCaseName === 'home_plan') {
      return <HomePlanIcon />;
    } else if (smallCaseName === 'patio') {
      return <PatioIcon />;
    } else if (smallCaseName === 'laundry_room') {
      return <LaundryIcon />;
    } else if (smallCaseName === 'garage') {
      return <GarageIcon />;
    } else if (smallCaseName === 'attic') {
      return <AtticIcon />;
    } else if (smallCaseName === 'hallway') {
      return <HallwayIcon />;
    } else if (smallCaseName === 'porch') {
      return <PorchIcon />;
    } else if (smallCaseName === 'closet') {
      return <ClosetIcon />;
    }
  };

  const _handleGeneratePDF = async () => {
    try {
      if (currentWorkspace?.credits > 0) {
        setIsLoading(true);
        const slides = document.querySelectorAll('.templateBg_1');

        // Create a jsPDF instance
        const pdf = new jsPDF();
        // Function to loop through each slide and add it to the PDF
        async function addSlideToPDF(index) {
          if (index < slides.length) {
            const slide = slides[index];
            await processSlide(slide);
            const canvas = await html2canvas(slide);
            // const imageData = canvas.toDataURL('image/jpeg', 1.0);
            let imgData;
            // if (index === slides.length - 1) {
            //   const response = await fetch(staticMapURL);
            //   const blob = await response.blob();
            //   imgData = URL.createObjectURL(blob);
            // } else {
            imgData = canvas.toDataURL('image/jpeg', 1.0);
            // }
            // Set PDF page height as per slide height
            const pdfHeight = (pdf.internal.pageSize.getWidth() / canvas.width) * canvas.height;

            // Add a new page for slides starting from the second slide
            pdf.addPage([pdf.internal.pageSize.getWidth(), pdfHeight]);
            const bgImg = new Image();
            bgImg.src = imgData;
            if (imgData) {
              pdf.addImage(bgImg, 'JPEG', 0, 0, pdf.internal.pageSize.getWidth(), pdfHeight);
            }

            // Process next slide
            await addSlideToPDF(index + 1);
          } else {
            // Save the PDF
            pdf.deletePage(1);
            pdf.save(`${propertyDetails.name}.pdf`);
            toast.success('PDF Downloaded Successfully.');
            // window.location.reload();
            setIsLoading(false);
          }
        }

        // Start adding slides to the PDF
        await addSlideToPDF(0);
        // await AxiosRequest.get("/property/updateUserCredits");
      } else {
        toast.error('Please upgrade your plan for exporting PDF');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error generating PDF:', error);
    }
  };

  const generatePowerPoint = async () => {
    try {
      // if (currentWorkspace.credits > 0) {
      setIsLoading(true);
      const slideArray = document.querySelectorAll('.templateBg_1');
      const pptx = new pptxgen();
      for (let i = 0; i < slideArray.length; i++) {
        const slide = pptx.addSlide();
        await processSlide(slideArray[i]);
        let canvas;
        // if (i === slideArray.length - 1) {
        //   const response = await fetch(staticMapURL);
        //   const blob = await response.blob();
        //   const reader = new FileReader(); // Define the reader here
        //   const base64Image = await new Promise((resolve) => {
        //     reader.onloadend = () => resolve(reader.result);
        //     reader.readAsDataURL(blob);
        //   });
        //   canvas = base64Image;
        // } else {
        const data = await html2canvas(slideArray[i], { dpi: 300 });
        canvas = data.toDataURL();
        // }
        if (canvas) {
          slide.addImage({
            data: canvas,
            w: '100%',
            h: '100%',
            x: 0,
            y: 0,
          });
        }
      }
      pptx.writeFile({ fileName: `${propertyDetails.name}` });
      // await AxiosRequest.get("/property/updateUserCredits");
      toast.success('Presentation downloaded successfully');
      setTimeout(() => {
        setIsLoading(false);
        // window.location.reload();
      }, [1000]);
      // } else {
      //   toast.error("Please upgrade your plan for export presentation");
      // }
    } catch (error) {
      setIsLoading(false);
      console.error('Capture Error:', error);
    }
  };

  const handleGeneratePDF = async () => {
    try {
      // if (currentWorkspace?.credits > 0) {
      setIsLoading(true);
      const slides = document.querySelectorAll('.templateBg_1');
      const pdf = new jsPDF({ unit: 'px', format: 'a4' });

      async function addSlideToPDF(index) {
        if (index < slides.length) {
          const slide = slides[index];
          await processSlide(slide);
          const canvas = await html2canvas(slide, { scale: 2 });

          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const imgHeight = (canvas.height * pdfWidth) / canvas.width;
          const topMargin = 20;

          if (index !== 0) {
            pdf.addPage();
          }

          pdf.setFillColor('#fff2f3');
          pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

          let verticalPosition = topMargin;
          if (imgHeight + topMargin * 2 <= pdfHeight) {
            verticalPosition = (pdfHeight - imgHeight) / 2;
          }

          if (canvas) {
            pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, verticalPosition / 2, pdfWidth, imgHeight);
          }
          await addSlideToPDF(index + 1);
        } else {
          pdf.save(`${propertyDetails.name}.pdf`);
          toast.success('PDF Downloaded Successfully.');
          setIsLoading(false);
        }
      }

      await addSlideToPDF(0);
      // await AxiosRequest.get("/property/updateUserCredits");
      // } else {
      //     toast.error("Please upgrade your plan for exporting PDF");
      //     setIsLoading(false);
      // }
    } catch (error) {
      setIsLoading(false);
      toast.error('Failed to export as PDF. Please try again later.');
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div className="ppt py-5">
      <div className="container">
        <div className="mb-5 d-block d-md-flex justify-content-between align-items-center">
          {params && params.id && (
            <Breadcrumb className="breadcrumbCustom fs20">
              <Breadcrumb.Item href={`/property-details/${params.id}`}>Property Details</Breadcrumb.Item>
              <Breadcrumb.Item active>Preview Presentation</Breadcrumb.Item>
            </Breadcrumb>
          )}

          {/* {nearByPlaces && nearByPlaces.length > 0 && staticMapURL && ( */}
          <div>
            <NavDropdown title={template} className="btnGreen">
              <NavDropdown.Item href="#" onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_1)}>
                {process.env.REACT_APP_TEMPLATE_1}
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_2)}>
                {process.env.REACT_APP_TEMPLATE_2}
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_3)}>
                {process.env.REACT_APP_TEMPLATE_3}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Export" className="btnGreen ms-4">
              <NavDropdown.Item href="#" onClick={generatePowerPoint}>
                Presentation
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={handleGeneratePDF}>
                PDF
              </NavDropdown.Item>
            </NavDropdown>
          </div>
          {/* )} */}
        </div>
      </div>

      <div className="_templatePage">
        <Container>
          {/* slide one start */}
          <section className="_templateSlide templateBg_1 slide_1">
            <div className="_bgWhite">
              <Row>
                <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                  <div className="leftArea">
                    <div>
                      <img src={realtixLogoBlack} alt="" />
                    </div>
                    <div className="content">
                      <h3 className="h3">{propertyDetails.name}</h3>
                      <p className="p">
                        {propertyDetails.type}, {propertyDetails.size.value} {propertyDetails.size.unit} Price :{' '}
                        {propertyDetails.priceSymbol}
                        {propertyDetails.price}
                      </p>
                    </div>
                    <div className="userDetail">
                      <span className="name">{currentUser && currentUser.name}</span>
                      {currentUser && currentUser.mobileNumber && (
                        <span className="mob">Mobile No. : {currentUser.mobileNumber}</span>
                      )}
                      <span className="mob">{currentUser && currentUser.email}</span>
                    </div>
                  </div>
                </Col>
                <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                  <div>
                    <img
                      src={propertyDetails.isVideoGenerate && propertyDetails.coverImage ? propertyDetails.coverImage : houseImg1}
                      style={{ minHeight: '500px' }}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </section>
          {/* slide two start */}
          <section className="_templateSlide templateBg_1 slide_2">
            <div className="_bgWhite">
              <div className="_slideTopTitle">
                <div className="w-100 d-flex justify-content-between align-items-start">
                  <h3 className="h3 mt-2">Description</h3>
                  {isEdit && isGenerationOwner && (
                    <div className="d-flex justify-content-end align-items-center me-2">
                      <button className="btnGreen" onClick={() => handleSaveDesc()} style={{ height: '40px' }}>
                        Save
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="contentArea">
                {isGenerationOwner ? (
                  <ContentEditable
                    html={(() => {
                      try {
                        // Attempt to parse editedDescription as JSON
                        return editedDescription ? JSON.parse(editedDescription).replace(/(\n\n)/g, '<br/><br/>') : '';
                      } catch (error) {
                        // If parsing fails, treat it as plain text
                        return editedDescription
                          ? editedDescription.replace(/(\n\n)/g, '<br/><br/>').replace(/\n/g, '<br/>')
                          : '';
                      }
                    })()} // Safely parse or handle plain text
                    onChange={handleDescriptionChange}
                    onClick={() => setIsEdit(true)}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: (() => {
                        try {
                          // Attempt to parse editedDescription as JSON
                          return editedDescription
                            ? JSON.parse(editedDescription).replace(/(\n\n)/g, '<br/><br/>')
                            : '';
                        } catch (error) {
                          // If parsing fails, treat it as plain text
                          return editedDescription
                            ? editedDescription.replace(/(\n\n)/g, '<br/><br/>').replace(/\n/g, '<br/>')
                            : '';
                        }
                      })(),
                    }}
                    onClick={() => setIsEdit(true)}
                  />
                )}
              </div>
            </div>
          </section>
          {/* slide three start */}
          <section className="_templateSlide templateBg_1 slide_3">
            <div className="_bgWhite">
              <div className="_slideTopTitle">
                <h3 className="h3">Property Details</h3>
              </div>
              <Row className="contentArea">
                <Col xs={5} sm={5} md={5} lg={5} xl={5} className="leftArea">
                  <div className="imgDiv">
                    <div
                      className="imgDiv_imgContainer"
                      style={{
                        backgroundImage: propertyDetails.isVideoGenerate && propertyDetails.coverImage
                          ? `url(${propertyDetails.coverImage})`
                          : `url(${houseImg1})`,
                      }}
                    />
                  </div>
                  <div className="imgDiv">
                    <div
                      className="imgDiv_imgContainer"
                      style={{
                        backgroundImage: propertyDetails.isVideoGenerate
                          ? `url(${propertyDetails.propertyImages[0].images[0]})`
                          : `url(${houseImg1})`,
                      }}
                    />
                  </div>
                </Col>
                <Col xs={7} sm={7} md={7} lg={7} xl={7} className="rightArea">
                  <div className="row">
                    <div className="col-4">
                      <div className="iconsDiv">
                        <div className=" icon">
                          <BedRoomIcon />
                        </div>
                        <div className="title1">BedRooms</div>
                        <div className="title2">{propertyDetails.num_bedrooms || 3}</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="iconsDiv">
                        <div className=" icon">
                          <BathIcon />
                        </div>
                        <div className="title1">Baths</div>
                        <div className="title2">{propertyDetails.num_baths || 3}</div>
                      </div>
                    </div>
                    <div className="col-4 mb-5">
                      <div className="iconsDiv">
                        <div className=" icon">
                          <ParkingIcon />
                        </div>
                        <div className="title1">Parking</div>
                        <div className="title2">{propertyDetails.parking || propertyDetails.parking === 0 ? propertyDetails.parking : 1}</div>
                      </div>
                    </div>
                  </div>
                  <Row className="">
                    {propertyDetails?.isVideoGenerate &&
                      propertyDetails?.propertyImages.map((item, i) => (
                        <Col key={i} xs={4} sm={4} md={4} lg={4} xl={4}>
                          <div className="iconsDiv">
                            <span className="icon">{getIconByName(item.name)}</span>
                            <span className="title1">{item.name}</span>
                            {!propertyDetails?.propertyUrl &&
                              <span className="title2">
                                {item.length} x {item.width} {item.sizeUnit}
                              </span>
                            }
                          </div>
                        </Col>
                      ))}
                  </Row>
                  <div className="row mt-5">
                    <div className="col-4">
                      <div className="iconsDiv">
                        <div className="icon">
                          <TranscationTypeIcon />
                        </div>
                        <div className="title1">Transcation Type</div>
                        <div className="title2">{propertyDetails.transactionType}</div>
                      </div>
                    </div>
                    <div className="col-4">
                      <div className="iconsDiv">
                        <div className="icon">
                          <PropertySizeIcon />
                        </div>
                        <div className="title1">Property Size</div>
                        <div className="title2">
                          {propertyDetails.size.value} {propertyDetails.size.unit}
                        </div>
                      </div>
                    </div>
                    <div className="col-4 mb-5">
                      <div className="iconsDiv">
                        <div className="icon">
                          <DollarIcon />
                        </div>
                        <div className="title1">Property Price</div>
                        <div className="title2">
                          {propertyDetails.priceSymbol}
                          {propertyDetails.price}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </section>
          {/* slide four start */}
          {/* slide five start */}
          {/* slide six start */}
          {propertyDetails?.propertyImages.map((data, index) => {
            return (
              <section className="_templateSlide templateBg_1 slide_4">
                <div className="_bgWhite">
                  <div className="d-flex justify-content-between align-items-start mb-5">
                    <div className="_slideTopTitle">
                      <h3 className="h3">{data.name}</h3>
                    </div>
                  </div>
                  <Row className="contentArea">
                    <Col xs={4} sm={4} md={4} lg={4} xl={4} className="leftArea">
                      <div className="imgDiv">
                        <div
                          className="imgDiv_imgContainer"
                          style={{
                            backgroundImage: data.images[0] ? `url(${data.images[0]})` : `url(${houseImg1})`,
                          }}
                        />
                      </div>
                    </Col>
                    {propertyDetails?.presentation && propertyDetails?.presentation.length > 0 ? (
                      <>
                        {propertyDetails?.presentation
                          .filter((presentation) => presentation.type === data.name)
                          .map((presentation, index) => (
                            <Col xs={8} sm={8} md={8} lg={8} xl={8} className="rightArea">
                              <h2 className="title">{presentation.content.title}</h2>
                              <h5 className="title2">{presentation.content.subtitle}</h5>
                              <p className="desc">{presentation.content.description}</p>
                            </Col>
                          ))}
                      </>
                    ) : (
                      ''
                    )}
                  </Row>
                </div>
              </section>
            );
          })}
          {nearByPlaces.length > 0 && (
            <section
              className={
                nearByPlaces.some((data) => data.type === 'restaurant' || data.type === 'grocery_or_supermarket')
                  ? '_templateSlide templateBg_1 slide_6'
                  : 'd-none'
              }
            >
              <div className="_bgWhite">
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div className="_slideTopTitle">
                      <h3 className="h3">Restaurants</h3>
                    </div>
                    <Row className="g-0">
                      {nearByPlaces.map((data, index) => {
                        return (
                          <>
                            {data.type === 'restaurant' && (
                              <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} className="px-1">
                                <div className="cardDiv">
                                  <div className="imgDiv">
                                    <div
                                      className="imgDiv_imgContainer"
                                      style={{
                                        backgroundImage: imageSrc[index]
                                          ? `url(${imageSrc[index]})`
                                          : `url(${houseImg1})`,
                                      }}
                                    />
                                  </div>
                                  <div className="cardContent">
                                    <h5 className="title">{data.name}</h5>
                                    <div className="address">
                                      <span className="fw-bold">Address : </span>
                                      <span>{data.vicinity}</span>
                                    </div>
                                    <div className="address">
                                      <span className="fw-bold">Distance : </span>
                                      <span>{data.distance} km</span>
                                    </div>
                                    <div className="address">
                                      <span className="fw-bold">Rating : </span>
                                      <span>{generateStars(data.rating)}</span>
                                      <span>{data.user_ratings_total || 0} Review</span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div className="_slideTopTitle">
                      <h3 className="h3">Walmart Supercentre</h3>
                    </div>
                    <Row className="g-0">
                      {nearByPlaces.map((data, index) => {
                        return (
                          <>
                            {data.type === 'grocery_or_supermarket' && (
                              <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} className="px-1">
                                <div className="cardDiv">
                                  <div className="imgDiv">
                                    <div
                                      className="imgDiv_imgContainer"
                                      style={{
                                        backgroundImage: imageSrc[index]
                                          ? `url(${imageSrc[index]})`
                                          : `url(${houseImg1})`,
                                      }}
                                    />
                                  </div>
                                  <div className="cardContent">
                                    <h5 className="title">{data.name}</h5>
                                    <div className="address">
                                      <span className="fw-bold">Address : </span>
                                      <span>{data.vicinity}</span>
                                    </div>
                                    <div className="address">
                                      <span className="fw-bold">Distance : </span>
                                      <span>{data.distance} km</span>
                                    </div>
                                    <div className="address">
                                      <span className="fw-bold">Rating : </span>
                                      <span>{generateStars(data.rating)}</span>
                                      <span>{data.user_ratings_total || 0} Review</span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </div>
            </section>
          )}
          {/* slide sevel start */}
          {nearByPlaces.length > 0 && (
            <section
              className={
                nearByPlaces.some((data) => data.type === 'school' || data.type === 'hospital')
                  ? '_templateSlide templateBg_1 slide_6'
                  : 'd-none'
              }
            >
              <div className="_bgWhite">
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div className="_slideTopTitle">
                      <h3 className="h3">School</h3>
                    </div>
                    <Row className="g-0">
                      {nearByPlaces.map((data, index) => {
                        return (
                          <>
                            {data.type === 'school' && (
                              <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} className="px-1">
                                <div className="cardDiv">
                                  <div className="imgDiv">
                                    <div
                                      className="imgDiv_imgContainer"
                                      style={{
                                        backgroundImage: imageSrc[index]
                                          ? `url(${imageSrc[index]})`
                                          : `url(${houseImg1})`,
                                      }}
                                    />
                                  </div>
                                  <div className="cardContent">
                                    <h5 className="title">{data.name}</h5>
                                    <div className="address">
                                      <span className="fw-bold">Address : </span>
                                      <span>{data.vicinity}</span>
                                    </div>
                                    <div className="address">
                                      <span className="fw-bold">Distance : </span>
                                      <span>{data.distance} km</span>
                                    </div>
                                    <div className="address">
                                      <span className="fw-bold">Rating : </span>
                                      <span>{generateStars(data.rating)}</span>
                                      <span>{data.user_ratings_total || 0} Review</span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div className="_slideTopTitle">
                      <h3 className="h3">Hospital</h3>
                    </div>
                    <Row className="g-0">
                      {nearByPlaces.map((data, index) => {
                        return (
                          <>
                            {data.type === 'hospital' && (
                              <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} className="px-1">
                                <div className="cardDiv">
                                  <div className="imgDiv">
                                    <div
                                      className="imgDiv_imgContainer"
                                      style={{
                                        backgroundImage: imageSrc[index]
                                          ? `url(${imageSrc[index]})`
                                          : `url(${houseImg1})`,
                                      }}
                                    />
                                  </div>
                                  <div className="cardContent">
                                    <h5 className="title">{data.name}</h5>
                                    <div className="address">
                                      <span className="fw-bold">Address : </span>
                                      <span>{data.vicinity}</span>
                                    </div>
                                    <div className="address">
                                      <span className="fw-bold">Distance : </span>
                                      <span>{data.distance} km</span>
                                    </div>
                                    <div className="address">
                                      <span className="fw-bold">Rating : </span>
                                      <span>{generateStars(data.rating)}</span>
                                      <span>{data.user_ratings_total || 0} Review</span>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            )}
                          </>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </div>
            </section>
          )}
          <section className="_templateSlide templateBg_1 slide_4">
            <div className="_bgWhite">
              <div className="d-flex justify-content-between align-items-start mb-2">
                <div className="_slideTopTitle">
                  <h3 className="h3">Contact Us</h3>
                </div>
              </div>
              <Row className="contentArea">
                <Col xs={8} sm={8} md={8} lg={8} xl={8} className="rightArea d-flex justify-content-center flex-column">
                  <div className="d-flex justify-content-center flex-column px-5">
                    <div className="font50Orange mb-4">{currentUser.name}</div>
                    <div className="font24BlackPPT mb-4" style={{ fontWeight: '400', wordBreak: 'break-word' }}>
                      {propertyDetails?.address}
                    </div>
                    <div className="font20BlackPPT">
                      {currentUser.mobileNumber && (
                        <div>
                          Mobile No.: <span className="font-bold">{currentUser.mobileNumber}</span>
                        </div>
                      )}
                      <div>
                        Email Id: <span className="font-bold">{currentUser.email}</span>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={4} sm={4} md={4} lg={4} xl={4} className="leftArea">
                  <div className="imgDiv">
                    <img className="" src={ContactUsImg} alt="" width="100%" height="100%" />
                  </div>
                </Col>
              </Row>
            </div>
          </section>
          {/* slide eight start */}
          {/* <section className="_templateSlide templateBg_1 slide_6">
            <div className="_bgWhite">
              <div className="_slideTopTitle">
                <h3 className="h3">Map Location</h3>
              </div>
              <div className="">
                <LoadScript
                  googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
                >
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={coordinates}
                    zoom={15}
                  >
                    <Marker position={coordinates} />
                  </GoogleMap>
                </LoadScript>
              </div>
            </div>
          </section> */}
        </Container>
      </div>
    </div>
  );
};

const RatingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="18"
    viewBox="0 0 118 28"
    fill="none"
  >
    <path
      d="M11.7133 5.8541C12.312 4.01148 14.9188 4.01148 15.5175 5.8541L16.2232 8.02603C16.4909 8.85008 17.2589 9.408 18.1253 9.408H20.409C22.3465 9.408 23.152 11.8872 21.5846 13.026L19.737 14.3684C19.0361 14.8776 18.7427 15.7804 19.0105 16.6044L19.7162 18.7764C20.3149 20.619 18.2059 22.1512 16.6385 21.0124L14.791 19.6701C14.09 19.1608 13.1408 19.1608 12.4398 19.6701L10.5923 21.0124C9.02483 22.1512 6.91587 20.619 7.51458 18.7764L8.22028 16.6044C8.48803 15.7804 8.19471 14.8776 7.49374 14.3684L5.64618 13.026C4.07875 11.8872 4.8843 9.408 6.82175 9.408H9.10545C9.97191 9.408 10.7398 8.85008 11.0076 8.02603L11.7133 5.8541Z"
      fill="#FBBC05"
    />
    <path
      d="M34.4047 5.8541C35.0034 4.01148 37.6102 4.01148 38.2089 5.8541L38.9146 8.02603C39.1824 8.85008 39.9503 9.408 40.8167 9.408H43.1004C45.0379 9.408 45.8434 11.8872 44.276 13.026L42.4284 14.3684C41.7275 14.8776 41.4341 15.7804 41.7019 16.6044L42.4076 18.7764C43.0063 20.619 40.8973 22.1512 39.3299 21.0124L37.4824 19.6701C36.7814 19.1608 35.8322 19.1608 35.1312 19.6701L33.2837 21.0124C31.7162 22.1512 29.6073 20.619 30.206 18.7764L30.9117 16.6044C31.1794 15.7804 30.8861 14.8776 30.1851 14.3684L28.3376 13.026C26.7702 11.8872 27.5757 9.408 29.5132 9.408H31.7969C32.6633 9.408 33.4312 8.85008 33.699 8.02603L34.4047 5.8541Z"
      fill="#FBBC05"
    />
    <path
      d="M57.098 5.8541C57.6967 4.01148 60.3036 4.01148 60.9023 5.8541L61.608 8.02603C61.8757 8.85008 62.6436 9.408 63.5101 9.408H65.7938C67.7312 9.408 68.5368 11.8872 66.9694 13.026L65.1218 14.3684C64.4208 14.8776 64.1275 15.7804 64.3953 16.6044L65.101 18.7764C65.6997 20.619 63.5907 22.1512 62.0233 21.0124L60.1757 19.6701C59.4747 19.1608 58.5256 19.1608 57.8246 19.6701L55.977 21.0124C54.4096 22.1512 52.3006 20.619 52.8993 18.7764L53.605 16.6044C53.8728 15.7804 53.5795 14.8776 52.8785 14.3684L51.0309 13.026C49.4635 11.8872 50.2691 9.408 52.2065 9.408H54.4902C55.3567 9.408 56.1246 8.85008 56.3923 8.02603L57.098 5.8541Z"
      fill="#FBBC05"
    />
    <path
      d="M79.7894 5.8541C80.3881 4.01148 82.995 4.01148 83.5937 5.8541L84.2994 8.02603C84.5671 8.85008 85.335 9.408 86.2015 9.408H88.4852C90.4226 9.408 91.2282 11.8872 89.6608 13.026L87.8132 14.3684C87.1122 14.8776 86.8189 15.7804 87.0867 16.6044L87.7924 18.7764C88.3911 20.619 86.2821 22.1512 84.7147 21.0124L82.8671 19.6701C82.1662 19.1608 81.217 19.1608 80.516 19.6701L78.6684 21.0124C77.101 22.1512 74.992 20.619 75.5907 18.7764L76.2964 16.6044C76.5642 15.7804 76.2709 14.8776 75.5699 14.3684L73.7224 13.026C72.1549 11.8872 72.9605 9.408 74.8979 9.408H77.1816C78.0481 9.408 78.816 8.85008 79.0837 8.02603L79.7894 5.8541Z"
      fill="#FBBC05"
    />
    <path
      d="M102.483 5.8541C103.082 4.01148 105.688 4.01148 106.287 5.8541L106.993 8.02603C107.26 8.85008 108.028 9.408 108.895 9.408H111.179C113.116 9.408 113.922 11.8872 112.354 13.026L110.507 14.3684C109.806 14.8776 109.512 15.7804 109.78 16.6044L110.486 18.7764C111.084 20.619 108.975 22.1512 107.408 21.0124L105.56 19.6701C104.86 19.1608 103.91 19.1608 103.209 19.6701L101.362 21.0124C99.7944 22.1512 97.6854 20.619 98.2841 18.7764L98.9898 16.6044C99.2576 15.7804 98.9642 14.8776 98.2633 14.3684L96.4157 13.026C94.8483 11.8872 95.6538 9.408 97.5913 9.408H99.875C100.741 9.408 101.509 8.85008 101.777 8.02603L102.483 5.8541Z"
      fill="#D9D9D9"
    />
  </svg>
);

export default Template2;
