import toast from 'react-hot-toast';

export const handleLogout = (navigate, setCurrentUser) => {
  sessionStorage.removeItem('currentUser');
  sessionStorage.removeItem('authToken');
  localStorage.removeItem('currentUser');
  toast.success('Logout successfully');

  setCurrentUser();
  // window.location.href = 'https://restatecopilot.io/';
  navigate('/');
  // window.location.reload();
};

export const getInitials = (fullName) => {
  if (!fullName || typeof fullName !== 'string') {
    return ''; // Return empty string if fullName is invalid
  }

  // Remove any extra spaces and split the name
  const names = fullName.trim().split(/\s+/);

  // Get first letter of first name and first letter of last name (if exists)
  const firstInitial = names[0] ? names[0][0] : '';
  const lastInitial = names[names.length - 1] ? names[names.length - 1][0] : '';

  // Combine and convert to uppercase
  return (firstInitial + lastInitial).toUpperCase();
};
