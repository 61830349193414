import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import User from "../../context/UserContext";
import AxiosRequest from "../../utils/AxiosRequest";
import toast from "react-hot-toast";
import Spinner from "../../shared/Spinner";
import "jspdf-autotable";
import { Review, ReviewGradiant } from "../../shared/Icons";
import Template2 from "./Template2";
import Template1 from "./Template1";
import Template3 from "./Template3";

const PPTForResidential = () => {
  const [propertyDetails, setPropertyDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [nearByPlaces, setNearByPlaces] = useState([]);
  const params = useParams();
  const { currentUser, _currentUser, currentWorkspace } = useContext(User);
  const [imageSrc, setImageSrc] = useState([]);
  const [editedDescription, setEditedDescription] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });
  const mapContainerStyle = {
    width: "100%",
    height: "600px",
  };
  const [staticMapURL, setStaticMapURL] = useState();
  const [template, setTemplate] = useState(process.env.REACT_APP_TEMPLATE_1);
  const [isImages, setIsImages] = useState(false);
  const location = useLocation();
  const { isGenerationOwner } = location.state || {}; // Use optional chaining to handle undefined state

  useEffect(() => {
    if (params && params.id) {
      getPropertyDetails();
      _currentUser();
      checkImages();
    }
  }, [params?.id]);

  useEffect(() => {
    //if there is no nearby place entry in db then call this api
    if (propertyDetails && propertyDetails?.nearByPlaces?.length === 0) {
      // getNearByPlaces();
    }
  }, [propertyDetails]);

  useEffect(() => {
    //call api for get map location image.
    if (propertyDetails && propertyDetails?.address) {
      getStaticImageUrl();
    }
  }, [propertyDetails]);

  const getStaticImageUrl = async () => {
    const addressForStaticMap = encodeURIComponent(propertyDetails.address);
    const result = await AxiosRequest.get(
      `/property/getStaticMap?address=${addressForStaticMap}`
    );
    setStaticMapURL(result.data.data);
  };

  const getPropertyDetails = async () => {
    try {
      setIsLoading(true);
      const result = await AxiosRequest.get(`/property/propertydetails/${params.id}/ppt`);
      setPropertyDetails(result.data.data);
      setEditedDescription(result.data.data.description);
      setCoordinates({
        lat: result.data.data.lat,
        lng: result.data.data.lng,
      });
      if (result.data.data?.nearByPlaces) {
        setNearByPlaces(result.data.data.nearByPlaces);
        setIsLoading(false);
      }
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      setIsLoading(false);
      console.log(err);
    }
  };

  const checkImages = async () => {
    const result = await AxiosRequest.get(`/propertyimages/fetch-images?id=${params.id}`);
    if (result?.data?.data && result?.data?.data?.images.length > 0) {
      setImageSrc(result?.data?.data?.images);
      setIsImages(true);
    } else {
      setIsImages(false);
    }
  };

  const getNearByPlaces = async () => {
    try {
      setIsLoading(true);
      const result = await AxiosRequest.get(
        `/property/near-by-places?type=residential&id=${propertyDetails._id}`
      );
      if (result.data?.data) {
        setNearByPlaces(result.data.data);
        getPropertyDetails();
      } else {
        setNearByPlaces([]);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || err?.message);
    }
  };

  useEffect(() => {
    if (nearByPlaces && nearByPlaces.length > 0 && !isImages) {
      // fetchImages();
    }
  }, [nearByPlaces, isImages]);

  const fetchImages = async () => {
    try {
      const imagePromises = nearByPlaces.map(async (data) => {
        if (data?.photos && data.photos.length > 0) {
          const src = await getPlaceImage(data.photos[0].photo_reference);
          return src;
        }
      });

      Promise.all(imagePromises)
        .then(async (imageUrls) => {
          setImageSrc(imageUrls);
          if(imageUrls[0]) {
            await AxiosRequest.post("/propertyimages/images", {
              id: propertyDetails._id,
              images: imageUrls,
            });
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    } catch (err) {
      console.log(err);
    }
  };

  //get nearby place images
  const getPlaceImage = async (photoReference) => {
    try {
      const response = await AxiosRequest.get(
        `/get-place-image?photoReference=${photoReference}`,
        {
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const dataURL = URL.createObjectURL(blob);
      return dataURL;
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || err?.message);
    }
  };
  // generate ppt

  const handleDescriptionChange = (e) => {
    setEditedDescription(JSON.stringify(e.target.value));
  };

  const handleSaveDesc = async () => {
    try {
      setIsLoading(true);
      await AxiosRequest.patch("/property", {
        propertyId: propertyDetails._id,
        description: editedDescription,
      });
      setIsEdit(false);
      await AxiosRequest.post("/property/updateWorkspaceCredits", {id: currentWorkspace._id});
      toast.success("Description updated successfully");
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error(err?.response?.data?.message || err?.message);
      console.log(err);
    }
  };

  const generateStars = (count) => {
    const stars = [];
    const fullStars = Math.floor(count); // Number of full stars
    const decimalPart = count - fullStars;
    for (let i = 1; i <= 5; i++) {
      if (i <= fullStars) {
        stars.push(Review("#FBBC05")); // Full yellow stars
      } else if (i === fullStars + 1 && decimalPart > 0 && decimalPart < 1) {
        stars.push(ReviewGradiant(i, decimalPart));
      } else {
        stars.push(Review("#D9D9D9")); // Empty stars
      }
    }
    return stars;
  };

  return (
    <div className="">
      {isLoading && <Spinner />}
      {propertyDetails && (
        <>
          {template === process.env.REACT_APP_TEMPLATE_2 ? (
            <Template2
              propertyDetails={propertyDetails}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              imageSrc={imageSrc}
              nearByPlaces={nearByPlaces}
              handleDescriptionChange={handleDescriptionChange}
              handleSaveDesc={handleSaveDesc}
              editedDescription={editedDescription}
              generateStars={generateStars}
              mapContainerStyle={mapContainerStyle}
              coordinates={coordinates}
              setIsLoading={setIsLoading}
              staticMapURL={staticMapURL}
              params={params}
              template={template}
              setTemplate={setTemplate}
              currentUser={currentUser}
              isGenerationOwner={isGenerationOwner}
              currentWorkspace={currentWorkspace}
            />
          ) : template === process.env.REACT_APP_TEMPLATE_1 ? (
            <Template1
              propertyDetails={propertyDetails}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              imageSrc={imageSrc}
              nearByPlaces={nearByPlaces}
              handleDescriptionChange={handleDescriptionChange}
              handleSaveDesc={handleSaveDesc}
              editedDescription={editedDescription}
              generateStars={generateStars}
              mapContainerStyle={mapContainerStyle}
              coordinates={coordinates}
              setIsLoading={setIsLoading}
              staticMapURL={staticMapURL}
              params={params}
              template={template}
              setTemplate={setTemplate}
              currentUser={currentUser}
              isGenerationOwner={isGenerationOwner}
              currentWorkspace={currentWorkspace}
            />
          ) : (
            <Template3
              propertyDetails={propertyDetails}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              imageSrc={imageSrc}
              nearByPlaces={nearByPlaces}
              handleDescriptionChange={handleDescriptionChange}
              handleSaveDesc={handleSaveDesc}
              editedDescription={editedDescription}
              generateStars={generateStars}
              mapContainerStyle={mapContainerStyle}
              coordinates={coordinates}
              setIsLoading={setIsLoading}
              staticMapURL={staticMapURL}
              params={params}
              template={template}
              setTemplate={setTemplate}
              currentUser={currentUser}
              isGenerationOwner={isGenerationOwner}
              currentWorkspace={currentWorkspace}
            />
          )}
        </>
      )}
    </div>
  );
};

export default PPTForResidential;
