import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTourContext } from '../context/TourContext';
import { useContext, useEffect } from 'react';
import AxiosRequest from '../utils/AxiosRequest';
import User from '../context/UserContext';

export default function TourSteps() {
  const { setState, state } = useTourContext();
  const navigate = useNavigate();
  const { run, stepIndex, steps } = state;
  const {_currentUser} = useContext(User);

  useEffect(() => {
    // Define the steps
    setState({
      ...state,
      steps: [
        {
          target: '#generate',
          showSkipButton: true,
          content: <div style={{ fontSize: '20px' }}>Click here to start creating your listing</div>,
          disableBeacon: true,
          spotlightPadding: 0,
          disableScrolling: true,
        },
        {
          target: '#content',
          showSkipButton: true,
          content: <div style={{ fontSize: '20px' }}>Fill the details of the property</div>,
          spotlightPadding: 0,
          disableScrolling: true,
        },
        {
          target: '#content-options',
          showSkipButton: true,
          content: <div style={{ fontSize: '20px' }}>Customize the details you fill to get the better results</div>,
          spotlightPadding: 0,
          disableScrolling: true,
        },
        {
          target: '#generated-content',
          showSkipButton: true,
          content: <div style={{ fontSize: '20px' }}>Your generated property will be shown here</div>,
          disableBeacon: true,
          spotlightPadding: 0,
          disableScrolling: true,
          locale: {
            last: 'End Tour', // Custom text for the button in the last step
          },
        },
      ],
      //   run: true, // Start the tour
    });
  }, [setState]);

  const handleJoyrideCallback = async(data) => {
    const { action, index, type, status } = data;

    // console.log("action, index, type, status = ", action, index, type, status)

    if ([STATUS.FINISHED, STATUS.SKIPPED, STATUS.PAUSED].includes(status)) {
      await AxiosRequest.post("/user/tourCompleted");
      _currentUser()
      // End the tour and navigate to home
      setState({ ...state, run: false, stepIndex: 0, tourActive: false });
      navigate('/my-generation');
      return;
    }

    if (type === 'tour:end' || action === 'close') {
        // Handle the end of the tour or when the close button is clicked
        setState(prevState => ({
          ...prevState,
          run: false, // Stop the tour
          stepIndex: 0, // Reset the step index if necessary
        }));
        navigate('/my-generation');
        return
        // Additional logic to handle tour end or skip
      }

    if (type === EVENTS.STEP_AFTER || action === ACTIONS.CLOSE) {
      // Navigate based on the index
      if (index === 0 && action !== ACTIONS.PREV) {
        setState({ ...state, stepIndex: index + 1 });
        navigate('/add-property');
      } else if (index === 1 && action !== ACTIONS.PREV) {
        setState({ ...state, stepIndex: index + 1 });
      } else if (index === 2 && action !== ACTIONS.PREV) {
        setState({ ...state, stepIndex: index + 1 });
        navigate('/my-generation');
      } if (index === 3 && action === ACTIONS.PREV) {
        setState({ ...state, stepIndex: index - 1 });
        navigate('/add-property');
      } else if (index === 1 && action === ACTIONS.PREV) {
        setState({ ...state, stepIndex: index - 1 });
        navigate('/my-generation');
      } else if (index === 2 && action === ACTIONS.PREV) {
        setState({ ...state, stepIndex: index - 1 });
      } else if (stepIndex === 3) {
        // Handle previous action if needed
        setState({ ...state, run: false, stepIndex: 0, tourActive: false });
      }
    }
  };

  return (
    <>
      <Outlet />
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        stepIndex={stepIndex}
        steps={steps}
        disableOverlayClose={true}
        styles={{
            buttonNext: {
                backgroundColor: 'var(--primary, #E03C31)',
                fontWeight: 500
            }
        }}
      />
    </>
  );
}
