import React, { useEffect, useState } from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import PropertyLinkForm from '../app/PropertyPage/PropertyLinkForm';
import ContentSelection from '../app/PropertyPage/ContentSelectionForm';
import ManualPropertyForm from './ManualPropertyModel';

const CreatePropertyLinkModel = ({ show, onHide, redirectUrl }) => {
  const [url, setUrl] = useState('');
  const [showPropertyForm, setShowPropertyForm] = useState(false);
  const [showContentSelection, setShowContentSelection] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isManualEntry, setIsManualEntry] = useState(false);

  useEffect(() => {
    // Pre-fill the URL field with redirectUrl if available
    if (redirectUrl) {
      setUrl(redirectUrl);
    }
  }, [redirectUrl]);

  const validateUrl = (inputUrl) => {
    try {
      new URL(inputUrl);
      return true;
    } catch (err) {
      return false;
    }
  };

  const handleContinue = () => {
    if (!url.trim()) {
      toast.error('Please enter a URL');
      return;
    }

    if (!validateUrl(url)) {
      toast.error('Please enter a valid URL');
      return;
    }

    setShowPropertyForm(true);
  };

  const handleFormContinue = (values) => {
    setFormData(values);
    setShowContentSelection(true);
  };

  const handleClose = () => {
    setUrl('');
    onHide();
    setShowPropertyForm(false);
    setShowContentSelection(false);
    setFormData(null);
    setIsManualEntry(false);
  };

  const renderUrlModal = () => (
    <Modal show={show && !showPropertyForm} onHide={handleClose} centered className="create-modal" size="lg">
      {!isManualEntry && (
        <Modal.Header closeButton className="border-0">
          <Modal.Title className="w-100 text-center">Let's create</Modal.Title>
        </Modal.Header>
      )}
      <Modal.Body className="px-4 pb-4">
        {!isManualEntry ? (
          <>
            <p className="model-pera mb-3">Let us fetch the data for you</p>
            <div className="position-relative mb-4">
              <Form.Control
                type="text"
                placeholder="Paste your property URL here"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="input-group"
              />
              <Button
                variant="primary"
                onClick={handleContinue}
                className="position-absolute top-50 translate-middle-y"
                style={{
                  right: '10px',
                  backgroundColor: '#FF6B35',
                  borderColor: '#FF6B35',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
              >
                Continue
              </Button>
            </div>

            <div>
              <div className="text-center">
                <p className="model-or mt-3 mb-4">OR</p>
                <Button variant="" className="btnNewSecondary mb-2" onClick={() => setIsManualEntry(true)}>
                  Enter details manually
                </Button>
              </div>
            </div>
          </>
        ) : (
          <ManualPropertyForm
            onBack={() => setIsManualEntry(false)}
            onSubmit={handleFormContinue}
            setShowContentSelection={setShowContentSelection}
            setShowPropertyForm={setShowPropertyForm}
            setFormData={setFormData}
          />
        )}
      </Modal.Body>
    </Modal>
  );

  const renderPropertyFormModal = () => (
    <Modal
      show={show && showPropertyForm && !showContentSelection}
      onHide={handleClose}
      centered
      size="lg"
      className="property-form-modal"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title className="w-100 text-center">Add Property Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PropertyLinkForm redirectUrl={url} onContinue={handleFormContinue} />
      </Modal.Body>
    </Modal>
  );

  const renderContentSelectionModal = () => (
    <Modal
      show={show && showPropertyForm && showContentSelection}
      onHide={handleClose}
      centered
      size="lg"
      className="content-selection-modal"
    >
      <Modal.Header closeButton className="border-0 model-header"></Modal.Header>
      <Modal.Body>
        <ContentSelection formData={formData} onClose={handleClose} />
      </Modal.Body>
    </Modal>
  );

  return (
    <>
      {renderUrlModal()}
      {renderPropertyFormModal()}
      {renderContentSelectionModal()}
    </>
  );
};

export default CreatePropertyLinkModel;
