// PropertyLinkForm.jsx
import React from 'react';
import { Formik } from 'formik';
import { Button, Container, Form } from 'react-bootstrap';
import realtorImg from '../../assets/images/realtor.png';
import zillowImg from '../../assets/images/zillow.png';
import { propertyLinkSchema } from '../../components/Dashboard/propertyFormValidation';

const PropertyLinkForm = ({ redirectUrl, onContinue }) => {

  const initialValues = {
    platform: 'zillow',
    propertyUrl: '' || redirectUrl,
    type: '',
    furnishedStatus: '',
  };


  return (
    <>
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={propertyLinkSchema}
          onSubmit={(values) => {
            onContinue(values);
          }}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <Form onSubmit={handleSubmit} className="property-link-form">
              <div className="">
                <Form.Group style={{ marginBottom: '64px' }}>
                  <Form.Label className="form-label-20">Where is the link from?</Form.Label>
                  <Form.Control
                    type="text"
                    name="propertyUrl"
                    value={values.propertyUrl}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter property URL"
                    className={touched.propertyUrl && errors.propertyUrl ? 'is-invalid' : ''}
                    style={{ background: 'white' }}
                  />
                  {touched.propertyUrl && errors.propertyUrl && (
                    <div className="error-message">{errors.propertyUrl}</div>
                  )}
                </Form.Group>

                <Form.Group className="mb-4">
                  <div className="platform-options">
                    <div className="position-relative">
                      <div style={{ position: 'absolute', left: '40px', bottom: '30px' }}>
                        <img src={zillowImg} alt="Realtor" className="platform-image" />
                      </div>
                      <Form.Check
                        type="radio"
                        id="zillow"
                        name="platform"
                        value="zillow"
                        label="Zillow"
                        checked={values.platform === 'zillow'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="platform-radio"
                      />
                    </div>
                    <div className="position-relative">
                      <div style={{ position: 'absolute', left: '40px', bottom: '30px' }}>
                        <img src={realtorImg} alt="Realtor" className="platform-image" />
                      </div>
                      <Form.Check
                        type="radio"
                        id="realtor"
                        name="platform"
                        value="realtor"
                        label="Realtor"
                        checked={values.platform === 'realtor'}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="platform-radio"
                      />
                    </div>
                  </div>
                  {touched.platform && errors.platform && <div className="error-message">{errors.platform}</div>}
                </Form.Group>

                <div className="row mt-5">
                  <div className="heading4-parent col-12 col-md-6 mb-3">
                    <div className="w-100">
                      <select
                        className={`input-field20 ${touched.type && errors.type ? 'is-invalid' : ''}`}
                        id="type"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled selected>
                          Property Type
                        </option>
                        <option value="House">House</option>
                        <option value="Condo">Condo</option>
                        <option value="Townhouse">Townhouse</option>
                        <option value="Villa">Villa</option>
                        <option value="Apartment">Apartment</option>
                        <option value="Commercial">Commercial</option>
                      </select>
                      {touched.type && errors.type && <p className="text-danger text-start ms-2">{errors.type}</p>}
                    </div>
                  </div>

                  <div className="heading4-parent col-12 col-md-6 mb-3">
                    <div className="w-100">
                      <select
                        className={`input-field20 ${
                          touched.furnishedStatus && errors.furnishedStatus ? 'is-invalid' : ''
                        }`}
                        id="furnishedStatus"
                        name="furnishedStatus"
                        value={values.furnishedStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled selected>
                          Furnished Status
                        </option>
                        <option value="Furnished">Furnished</option>
                        <option value="Semi-Furnished">Semi-Furnished</option>
                        <option value="Unfurnished">Unfurnished</option>
                      </select>
                      {touched.furnishedStatus && errors.furnishedStatus && (
                        <p className="text-danger text-start ms-2">{errors.furnishedStatus}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center mt-5">
                  <Button type="submit" className="btnNewPrimary sm">
                    Continue
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </>
  );
};

export default PropertyLinkForm;
