import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AxiosRequest from '../../utils/AxiosRequest';
import toast from 'react-hot-toast';
import { Col, Dropdown, Modal, Nav, Row, Spinner, Table } from 'react-bootstrap';
import { CloseIcon, MembersIcon, MessageIcon } from '../../shared/Icons';
import moment from 'moment';
import defaultAvtar from '../../assets/images/default-avtar.png';

const WorkspaceSettingPopup = ({ workspacePopup, hide, currentUser, getAllWorkspaces, setWorkspacePopup }) => {
  const [status, setStatus] = useState('active');
  const [workspaceTitle, setWorkspaceTitle] = useState({
    value: '',
    updatedValue: '',
    showButtons: false,
  });
  const [inviteMember, setInviteMember] = useState({
    email: '',
    role: 'member',
  });
  const [inviteMemberList, setInviteMemberList] = useState([]);
  const [workspaceDetail, setWorkspaceDetail] = useState({
    data: null,
    loading: false,
  });
  const navigate = useNavigate();

  useEffect(() => {
    if (workspacePopup) getWorkspaceDetail();
  }, [workspacePopup]);

  const getWorkspaceDetail = async () => {
    try {
      setWorkspaceDetail((prev) => ({ data: null, loading: true }));
      const { data } = await AxiosRequest.get(`/workspace/current-work-spaces?id=${workspacePopup}`);
      setWorkspaceDetail((prev) => ({ ...prev, data: data.data }));
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      setWorkspaceDetail((prev) => ({ ...prev, loading: false }));
    }
  };

  const _filterMemberList = useMemo(() => {
    if (!workspaceDetail.loading && workspaceDetail.data !== null) {
      const activeList =
        workspaceDetail.data.workspace.members.length > 0
          ? workspaceDetail.data.workspace.members.filter((m) => m.status === 'active')
          : [];
      const pendingList =
        workspaceDetail.data.workspace.members.length > 0
          ? workspaceDetail.data.workspace.members.filter((m) => m.status === 'pending')
          : [];
      const workspaceUserAdmin = {
        _id: workspaceDetail?.data?.workspace?.user,
        name: workspaceDetail?.data?.user?.name,
        email: workspaceDetail?.data?.user?.email,
        image_url: workspaceDetail?.data?.user?.image || null,
        role: 'admin',
        status: 'active',
        joinDate: workspaceDetail?.data?.workspace?.createdAt || null,
        isWorkspaceSuperAdmin: true,
        // isLoggedInUser = ""
      };
      const _activeList = [workspaceUserAdmin, ...activeList];
      return { active: _activeList || [], pending: pendingList || [] };
    }
    return { active: [], pending: [] };
  }, [workspaceDetail, status]);

  const activeAdminsCount = useMemo(() => {
    const res = _filterMemberList?.active.length > 0 ? _filterMemberList.active.filter((m) => m.role === 'admin') : [];
    return res.length || 0;
  }, [_filterMemberList]);

  const isLoginUserWorkspaceAdmin = useMemo(() => {
    if (_filterMemberList.active.length > 0) {
      const check = _filterMemberList.active.find((m) => m.email === currentUser.email);
      return check?.role === 'admin' ? true : false;
    }
    return false;
  }, [_filterMemberList, currentUser]);

  const handleRemoveMemberFromInviteList = (item) => {
    const newList = inviteMemberList.filter((m) => m.email !== item.email);
    setInviteMemberList([...newList]);
  };

  const handleAddMembertoInviteList = () => {
    if (isLoginUserWorkspaceAdmin) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (inviteMemberList.find((m) => m.email === inviteMember.email)) {
        toast.error('email already added');
        return;
      }
      if (inviteMember.email.trim() !== '' && emailRegex.test(inviteMember.email.trim())) {
        setInviteMemberList((prev) => [...prev, inviteMember]);
        setInviteMember({ email: '', role: 'member' });
      } else {
        toast.error('Please enter a valid email address.');
      }
    }
  };

  const handleInviteMembers = async (e) => {
    try {
      e.target.disabled = true;
      e.target.innerHTML = 'Please wait';
      const { data } = await AxiosRequest.post(`/workspace/invite-member`, {
        members: [...inviteMemberList],
        id: workspacePopup,
      });
      toast.success(data.message);
      setInviteMemberList([]);
      getWorkspaceDetail();
    } catch (err) {
      toast.error('You cannot invite your own email address.' || err?.response?.data?.message || err?.message);
    } finally {
      e.target.disabled = false;
      e.target.innerHTML = 'Invite';
    }
  };

  const handleChangeRole = async ({ e, role, member }) => {
    try {
      e.target.disabled = true;
      e.target.innerHTML = 'Please wait';
      const { data } = await AxiosRequest.post(`/workspace/member-update`, {
        email: member.email,
        id: workspacePopup,
        role: role.toLowerCase(),
        status: member.status,
      });
      toast.success(data.message);
      getWorkspaceDetail();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      e.target.disabled = false;
      e.target.innerHTML = role;
    }
  };

  const handleRevokeMember = async ({ e, member }) => {
    try {
      e.target.disabled = true;
      e.target.innerHTML = 'Please wait';
      const { data } = await AxiosRequest.post(`/workspace/member-update`, {
        email: member.email,
        id: workspacePopup,
        role: member.role,
        status: 'revoke',
      });
      toast.success(data.message);
      getWorkspaceDetail();
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      e.target.disabled = false;
      e.target.innerHTML = 'Remove from workspace';
    }
  };

  // ========
  useEffect(() => {
    if (!workspaceDetail.loading && workspaceDetail.data !== null) {
      const title = workspaceDetail?.data?.workspace.name || '';
      setWorkspaceTitle((prev) => ({
        showButtons: false,
        value: title,
        updatedValue: title,
      }));
    }
  }, [workspaceDetail]);

  const handleWorkspaceTitleInputChange = (e) => {
    if (isLoginUserWorkspaceAdmin) {
      setWorkspaceTitle((prev) => ({
        ...prev,
        showButtons: true,
        updatedValue: e.target.value,
      }));
    }
  };

  const handleUpdateWorkspaceTitle = async (e) => {
    try {
      e.target.disabled = true;
      e.target.innerHTML = 'Please wait';
      const { data } = await AxiosRequest.post(`/workspace/update`, {
        id: workspacePopup,
        name: workspaceTitle.updatedValue,
      });
      toast.success(data.message);
      getWorkspaceDetail();
      getAllWorkspaces({ query: null });
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    } finally {
      e.target.disabled = false;
      e.target.innerHTML = 'Update';
    }
  };

  const handleCancelWorkspaceTitle = () => {
    setWorkspaceTitle((prev) => ({
      ...prev,
      showButtons: false,
      updatedValue: prev.value,
    }));
  };

  const roleDropdownUi = (item) => {
    const _disable = (role) => {
      const res =
        item.role.toLowerCase() === role.toLowerCase() ||
        (item.status === 'active' && item.role.toLowerCase() === 'admin' && activeAdminsCount < 2);
      return res;
    };
    return (
      <Dropdown className="dropdownRole">
        <Dropdown.Toggle variant="white" id="dropdown-basic" className="text-capitalize">
          {item.role}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {['Member', 'Admin'].map((role, i) => (
            <Dropdown.Item
              disabled={_disable(role)}
              role="button"
              onClick={(e) => (!_disable(role) ? handleChangeRole({ e, role, member: item }) : null)}
              key={i}
              className="text-capitalize"
            >
              {role}
            </Dropdown.Item>
          ))}
          <hr className="mb-2" />
          <Dropdown.Item
            className="text-danger fw600"
            style={{ opacity: item.email === currentUser.email ? '0.5' : '1' }}
            role="button"
            disabled={!isLoginUserWorkspaceAdmin || item.email === currentUser.email}
            onClick={(e) => {
              if (isLoginUserWorkspaceAdmin && item.email !== currentUser.email) {
                handleRevokeMember({ e, member: item });
              }
            }}
          >
            Remove from workspace
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return (
    <Modal show={workspacePopup} backdrop="static" size="lg" onHide={hide} className="modalCustom2">
      <Modal.Header closeButton>Workspace settings</Modal.Header>
      <Modal.Body>
        <div className="d-md-flex  flex-col align-items-center justify-content-between grayBox upgradePlanArea">
          <div className="me-2">
            <span className="title">Upgrade to Professional</span>
            <p className="desc">
              Unlock 2000 Credits, Description & Social Description, Showcase Video, Presentation, 24x7 Support
            </p>
          </div>
          <button
            style={{ minWidth: 'max-content' }}
            className="btn btnNewPrimary"
            onClick={() => {
              navigate('/pricing');
              setWorkspacePopup(null);
            }}
          >
            Upgrade Plan
          </button>
        </div>
        <div className="mt-3 mb-4">
          <div className="heading4-parent col-12">
            <div className="heading-2-wrapper ps-1">
              <b className="home">Workspace name</b>
            </div>
            <div className="w-100">
              {/* ============== */}
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="customInviteMemberInputArea col">
                  <input
                    disabled={!isLoginUserWorkspaceAdmin}
                    className="w-100"
                    placeholder="John Dao's Workspace"
                    type="text"
                    value={workspaceTitle.updatedValue}
                    style={{ border: 0 }}
                    onChange={handleWorkspaceTitleInputChange}
                  />
                </div>
                {workspaceTitle.showButtons && (
                  <div className="d-flex flex-wrap">
                    <button
                      onClick={(e) => handleUpdateWorkspaceTitle(e)}
                      className="btn btnNewPrimary smallBtn "
                      type="button"
                    >
                      Update
                    </button>
                    <button onClick={handleCancelWorkspaceTitle} className="btn btnPrimary smallBtn" type="button">
                      Cancel
                    </button>
                  </div>
                )}
              </div>
              {/* ========== */}

              <p className="fw600 text-start mt-2 ps-1">E.g. your team or company name.</p>
            </div>
          </div>
          <div className="heading4-parent col-12">
            <div className="heading-2-wrapper ps-1">
              <b className="home">Invite others to this workspace</b>
            </div>
            <p className="fw600 text-start mb-2 ps-1">Invite by email address</p>
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-between flex-wrap gap-20">
                <div className="customInviteMemberInputArea col">
                  {inviteMemberList?.length > 0 ? (
                    <div className="d-flex justify-content-between align-items-start">
                      <div className="mList mb-2">
                        {inviteMemberList.map((item, i) => (
                          <div className="item" key={i}>
                            <span className="email1 me-2">{item.email}</span>
                            <span className="role2 me-2">{item.role}</span>
                            <span onClick={() => handleRemoveMemberFromInviteList(item)} className="btnRemove3">
                              <CloseIcon />
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : null}
                  <div>
                    <input
                      disabled={!isLoginUserWorkspaceAdmin}
                      className=""
                      style={{ border: '1px solid #ccc', borderRadius: '6px', height: '30px', paddingLeft: '10px' }}
                      placeholder="Add People"
                      type="text"
                      value={inviteMember.email}
                      onChange={(e) => {
                        if (isLoginUserWorkspaceAdmin)
                          setInviteMember({
                            ...inviteMember,
                            email: e.target.value,
                          });
                      }}
                    />
                    <select
                      disabled={!isLoginUserWorkspaceAdmin}
                      value={inviteMember.role}
                      onChange={(e) => {
                        if (isLoginUserWorkspaceAdmin)
                          setInviteMember({
                            ...inviteMember,
                            role: e.target.value,
                          });
                      }}
                    >
                      {['member', 'admin'].map((role, i) => (
                        <option className="text-capitalize" key={i} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                    <button
                      disabled={!isLoginUserWorkspaceAdmin}
                      onClick={handleAddMembertoInviteList}
                      className="btnNewPrimary sm smallBtn ms-2"
                      type="button"
                    >
                      Add
                    </button>
                  </div>
                </div>
                {inviteMemberList.length > 0 ? (
                  <button onClick={(e) => handleInviteMembers(e)} className="btn btnNewPrimary" type="button">
                    Invite
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* ========= */}
        <Row>
          <Col>
            <Nav className="customTab2" variant="pills" activeKey={status} onSelect={(key) => setStatus(key)}>
              <Nav.Item>
                <Nav.Link className="navLink" eventKey="active">
                  <span className="me-2">
                    <MembersIcon />
                  </span>
                  <span>Active Members ({_filterMemberList?.active?.length || 0})</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="navLink" eventKey="pending">
                  <span className="me-2">
                    <MessageIcon />
                  </span>
                  <span>Invitations ({_filterMemberList?.pending?.length || 0})</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
            {status === 'active' && (
              <Table bordered className="memberTable">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th className="text-start">Join date</th>
                    <th className="text-center">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {workspaceDetail.loading ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <span>
                          <Spinner size="sm" /> Loading
                        </span>
                      </td>
                    </tr>
                  ) : _filterMemberList[status]?.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <strong>No member found!</strong>
                      </td>
                    </tr>
                  ) : (
                    _filterMemberList[status].map((item, index) => (
                      <React.Fragment key={index}>
                        <tr>
                          <td>
                            <div className="memberArea d-flex align-items-center">
                              <span className="profileImg">
                                <img src={item?.image_url || defaultAvtar} />
                              </span>
                              <div className="d-flex flex-column ms-2">
                                <span className="title">
                                  {item?.name
                                    ? `${item?.name}${item.email === currentUser.email ? ' (you)' : ''}`
                                    : '-'}
                                </span>
                                <span className="title2">{item.email}</span>
                              </div>
                            </div>
                          </td>
                          <td className="text-start">
                            <span className="date">
                              {item.joinDate ? moment(item.joinDate).format('MMM DD, YYYY') : '-'}
                            </span>
                          </td>
                          <td className="text-center">
                            {isLoginUserWorkspaceAdmin ? roleDropdownUi(item) : item.role}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))
                  )}
                </tbody>
              </Table>
            )}
            {status === 'pending' && (
              <Table bordered className="memberTable">
                <thead>
                  <tr>
                    <th>Email</th>
                    <th className="text-start">Last Sent</th>
                    <th className="text-center">Role</th>
                  </tr>
                </thead>
                <tbody>
                  {workspaceDetail.loading ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <span>
                          <Spinner size="sm" /> Loading
                        </span>
                      </td>
                    </tr>
                  ) : _filterMemberList[status]?.length === 0 ? (
                    <tr>
                      <td className="text-center" colSpan={3}>
                        <strong>No member found!</strong>
                      </td>
                    </tr>
                  ) : (
                    _filterMemberList[status].map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className="memberArea d-flex align-items-center">
                            <div className="d-flex flex-column ms-1">
                              <span className="title2">{item.email}</span>
                            </div>
                          </div>
                        </td>
                        <td className="text-start">
                          <span className="date">
                            {item.expiredAt
                              ? moment(item.expiredAt).subtract(10, 'minutes').format('MMM DD, YYYY')
                              : '-'}
                          </span>
                        </td>
                        <td className="text-center">{isLoginUserWorkspaceAdmin ? roleDropdownUi(item) : item.role}</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btnNewPrimary" onClick={hide}>
          Done
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default WorkspaceSettingPopup;
