import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GoogleIcon, LeftArrowIcon } from '../../shared/Icons';
import { useAuth } from '../../context/AuthContext';
import AxiosRequest from '../../utils/AxiosRequest';
import User from '../../context/UserContext';
import toast from 'react-hot-toast';
import { Spinner } from 'react-bootstrap';
import OtpModel from '../../models/otpModel';
import LogoImage from '../../assets/images/LogoNew.png';
import MessagingService from '../../services/MessagingService';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email format').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const Login = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const workspaceId = params?.workspaceId || null;
  const email = query.get('email');
  const workspaceName = query.get('name');

  const [isLoading, setIsLoading] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [userId, setUserId] = useState('');

  const { signInWithGoogle, login, handleAuthError } = useAuth();

  const { currentUser, setCurrentUser, handleSelectCurrentWorkspace } = React.useContext(User);

  useEffect(() => {
    const fetchData = async () => {
      if (workspaceId && email) {
        try {
          const checkResponse = await AxiosRequest.post(`/user/check-email`, {
            email: email,
          });
          console.log('checkResponse', checkResponse.data.data);
          if (checkResponse.data.data === false) {
            navigate(`/signup?workspaceId=${workspaceId}&email=${email}&name=${workspaceName}`);
          }

          // const acceptInvitationResponse = await AxiosRequest.post(`/workspace/accept-invitation`, {
          //   email: email,
          //   id: workspaceId
          // });
          // console.log('acceptInvitationResponse', acceptInvitationResponse.data.data);
        } catch (err) {
          console.log('err', err);
          // toast.error(err?.response?.data?.message || err?.message);
        }
      }
    };

    fetchData();
  }, [workspaceId, email]);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);
      setIsLoading(true);
      try {
        login(values.email, values.password)
          .then(async (res) => {
            if (res?.user?.accessToken) {
              const obj = {
                idToken: res?.user?.accessToken,
                notificationToken: 'lkjhlkjhkljh',
                workSpaceId: workspaceId || null,
              };
              const { data } = await AxiosRequest.post('user/login', obj);
              if (data?.statusCode === 200 && data?.data?.user?.isVerified) {
                sessionStorage.setItem('authToken', data?.token);
                sessionStorage.setItem('currentUser', JSON.stringify(data?.data?.user));
                localStorage.setItem('currentUser', JSON.stringify(data?.data?.user));
                handleSelectCurrentWorkspace(data?.data?.workSpace);
                setCurrentUser(data?.data?.user);
                navigate('/dashboard');
              } else {
                setUserId(data?.data._id);
                setIsLoading(false);
                setSubmitting(false);
                toast.error(data?.message);
                setShowOtpModal(true);
              }
            }
            setIsLoading(false);
            setSubmitting(false);
          })
          .catch((err) => {
            setIsLoading(false);
            setSubmitting(false);
            toast.error(handleAuthError(err));
          });
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message);
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  const handleGoogleAuth = () => {
    signInWithGoogle()
      .then(async (res) => {
        setIsLoading(true);
        const messagingToken = await MessagingService.getMessagingToken();
        const reqObj = {
          idToken: res?.user?.accessToken || res?.accessToken,
          notificationToken: messagingToken,
          workSpaceId: workspaceId || null,
        };
        const { data } = await AxiosRequest.post('user/google-signup', reqObj);
        if (data?.statusCode === 200 && data?.data?.user?.isVerified) {
          sessionStorage.setItem('authToken', data?.token);
          sessionStorage.setItem('currentUser', JSON.stringify(data?.data?.user));
          localStorage.setItem('currentUser', JSON.stringify(data?.data?.user));
          handleSelectCurrentWorkspace(data?.data?.workSpace);
          setCurrentUser(data?.data?.user);
          navigate('/dashboard');
        } else {
          toast.error(data?.message);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(handleAuthError(err));
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      formik.handleSubmit();
    }
  };

  return (
    <div className="row g-0">
      <div className="col-lg-7 col-xl-8 d-none d-lg-block login-bg">
        <a href="/">
          <img src={LogoImage} alt="logo" style={{ position: 'absolute', top: '30px', left: '50px' }} />
        </a>
      </div>
      <div className="col-lg-5 col-xl-4 col-12">
        <div className="sign-up">
          <img className="sign-up-child" alt="" src="/group-8711.svg" />
          <div className="logo-parent">
            <img className="d-lg-none d-block" src={LogoImage} alt="logo" />
            {/* <div className="logo" onClick={() => navigate('/')}>
              <img src={LogoImage} alt="" className="img-fluid" />
            </div> */}
            {/* <div className="heading3">
              <div className="heading-3 text-center">
                {workspaceId ? `Login to join ${workspaceName} on Restate Copilot` : 'Login'}
              </div>
            </div> */}

            <div className="w-100">
              <div className="heading mb-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
                {!workspaceId && (
                  <a href="/">
                    <LeftArrowIcon />
                  </a>
                )}
                <div>{workspaceId ? `Login to join ${workspaceName} on Restate Copilot` : 'Login'}</div>
                <div></div>
              </div>
            </div>

            <div className="w-100">
              <input
                type="email"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="input-field"
                placeholder="Email ID"
              />
              {formik.errors.email && <p className="text-danger ms-2">{formik.errors.email}</p>}
            </div>
            <div className="w-100">
              <input
                type="password"
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className="input-field"
                placeholder="Password"
                onKeyDown={handleKeyPress}
              />
              {formik.errors.password && <p className="text-danger ms-2">{formik.errors.password}</p>}
            </div>
            <Link className="forgot-password-wrapper" to="/forget-password">
              <span className="forgot-password">Forgot Password?</span>
            </Link>

            <div className="line-parent">
              <div className="frame-child" />
              <div className="or-login-with">OR</div>
              <div className="frame-child" />
            </div>
            <div className="google-parent">
              <div
                onClick={() => {
                  handleGoogleAuth();
                }}
                style={{
                  backgroundColor: 'transparent',
                }}
              >
                <GoogleIcon />
                <span>Sign in with Google</span>
              </div>
            </div>

            <button
              className="btnNewPrimary my-5"
              onClick={formik.handleSubmit}
              type="submit"
              disabled={formik.isSubmitting || isLoading}
            >
              <div className="login">
                {formik.isSubmitting || isLoading ? (
                  <>
                    <Spinner animation="border" size="sm" className="me-2" /> Please wait...
                  </>
                ) : (
                  'LOGIN'
                )}
              </div>
            </button>
            <div className="dont-have-an-account-sign-up-wrapper">
              <div className="forgot-password">
                <span>Don’t have an account?</span>
                <Link className="login1 ms-1" to="/signup">
                  <b>Sign Up</b>
                </Link>
              </div>
            </div>
            {/* <Link className="login1" to="/">
              <b>Back to Home</b>
            </Link> */}
          </div>
          <OtpModel
            showOtpModal={showOtpModal}
            setShowOtpModal={setShowOtpModal}
            userEmail={currentUser?.email}
            userId={userId}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
