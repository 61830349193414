import React from "react";

const AudioPlayer = ({ audioUrl, isPlaying, setIsPlaying }) => {
  const audioRef = React.createRef();

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
    <div>
      <audio ref={audioRef} src={audioUrl} />
      <button type="button" className="audio-button" onClick={handlePlayPause}>
        {isPlaying ? (
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMidYMid meet"
          >
            <g transform="translate(0.5 0.5)">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.8625 2C2.38615 2 2 2.38615 2 2.8625V17.2375C2 17.7138 2.38615 18.1 2.8625 18.1H4.5875C5.06385 18.1 5.45 17.7138 5.45 17.2375V2.8625C5.45 2.38615 5.06385 2 4.5875 2H2.8625ZM12.0625 2C11.5862 2 11.2 2.38615 11.2 2.8625V17.2375C11.2 17.7138 11.5862 18.1 12.0625 18.1H13.7875C14.2638 18.1 14.65 17.7138 14.65 17.2375V2.8625C14.65 2.38615 14.2638 2 13.7875 2H12.0625Z"
                fill="#FF6B35"
              />
            </g>
          </svg>
        ) : (
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.75 9.27828C19.3056 9.599 19.3056 10.4009 18.75 10.7216L6.25 17.9385C5.69444 18.2593 5 17.8583 5 17.2168V2.78306C5 2.14156 5.69444 1.74063 6.25 2.06138L18.75 9.27828Z"
              fill="#FF6B35"
            />
          </svg>
        )}
      </button>
    </div>
  );
};

export default AudioPlayer;
