import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { StartIcon } from '../shared/NewIcon';

const VideoTemplateSelection = ({ videoTemplates, onSubmit, onBack, isSubmitting }) => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [playingVideoId, setPlayingVideoId] = useState(null);

  const handleTemplateSelect = (template) => {
    setSelectedTemplate(template);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (selectedTemplate) {
      onSubmit(selectedTemplate);
    }
  };

  const handleVideoPlay = (templateId) => {
    setPlayingVideoId(templateId);
  };

  return (
    <div className="video-template-selection">
      <h2 className="mb-5">Select a Video Template</h2>
      <div className="row g-4">
        {videoTemplates &&
          videoTemplates.length > 0 &&
          videoTemplates?.map((template) => (
            <div key={template._id} className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <Card
                onClick={() => handleTemplateSelect(template)}
                className="cursor-pointer"
                style={{
                  background: selectedTemplate?._id === template._id ? 'rgba(255, 107, 53, 0.10)' : 'transparent',
                }}
              >
                <Card.Body className="p-0">
                  <div
                    className="video-container"
                    style={{
                      position: 'relative',
                      width: '100%',
                      backgroundColor: '#000',
                      borderRadius: '5px',
                    }}
                  >
                    {playingVideoId === template._id ? (
                      <video
                        width="100%"
                        height="100%"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          borderRadius: '5px',
                        }}
                        controls
                        autoPlay
                      >
                        <source src={template.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <>
                        <img
                          src={template?.coverImageUrl}
                          alt="Video Thumbnail"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'fill',
                            borderRadius: '5px',
                          }}
                        />

                        <button
                          className="play-button"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent card selection
                            handleVideoPlay(template._id);
                          }}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            background: 'transparent',
                            border: 'none',
                            borderRadius: '50%',
                            padding: '10px 15px',
                            color: '#fff',
                            cursor: 'pointer',
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="32" height="32">
                            <circle cx="32" cy="32" r="32" fill="#f1482d" />
                            <polygon points="25,18 25,46 46,32" fill="#fff" />
                          </svg>
                        </button>
                      </>
                    )}
                  </div>
                  <div>
                    <div style={{ fontSize: '12px', fontWeight: '600', padding: '10px' }}>{template.name}</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
      <div className="mt-4 d-flex justify-content-end">
        <Button onClick={onBack} variant="" className="btnNewPrimary sm me-3">
          Back
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={!selectedTemplate || isSubmitting}
          variant=""
          type="button"
          className="btnNewPrimary sm"
        >
          {isSubmitting ? (
            'Submitting...'
          ) : (
            <>
              Go <StartIcon />
            </>
          )}
        </Button>
      </div>
    </div>
  );
};

export default VideoTemplateSelection;
