import { HomeIcon } from 'lucide-react';
import Generation from '../GenerationPage/Generation';
import {
  IntegrationsIcon,
  LogoutIcon,
  PlansIcon,
  ProfileIcon,
  SocialIcon,
  TemplateIcon,
  WorkSpaceIcon,
} from '../../shared/NewIcon';
import Profile from '../Profile/index';
import Pricing from '../Price/index';
import Template from '../../components/Dashboard/Template';
import AllWorkspace from '../workspace/AllWorkspace';

export const menuItems = [
  {
    title: 'Home',
    content: <Generation />,
    icon: <HomeIcon />,
  },
  {
    title: 'Templates',
    content: <Template />,
    icon: <TemplateIcon />,
    badge: 'New',
  },
  {
    title: 'Workspaces',
    content: <AllWorkspace />,
    icon: <WorkSpaceIcon />,
    // subItems: ['Harry Barry', 'Chintan', 'Dinesh'],
  },
  {
    title: 'Profile',
    icon: <ProfileIcon />,
    content: <Profile />,
  },
  {
    title: 'Plans',
    icon: <PlansIcon />,
    content: <Pricing />,
  },
  {
    title: 'Integrations',
    icon: <IntegrationsIcon />,
    badge: 'Upcoming',
  },
  {
    title: 'Social Campaigns',
    icon: <SocialIcon />,
    badge: 'Upcoming',
  },
  {
    title: 'Logout',
    icon: <LogoutIcon />,
  },
];
