import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, Nav } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import defaultAvtar from '../../assets/images/default-avtar.png';
import PlaceholdeImage from '../../assets/images/Logo.png';
import { useTourContext } from '../../context/TourContext';
import User from '../../context/UserContext';
import { AllPresentationIcon, SearchIcon, UserIcon } from '../../shared/Icons';
import Spinner from '../../shared/Spinner';
import AxiosRequest from '../../utils/AxiosRequest';
import { NotificationIcon, SettingIcon } from '../../shared/NewIcon';
import NotificationComponent from '../../components/Dashboard/Notification';

const Generation = () => {
  const [userProperty, setUserProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalGenerations, setTotalGenerations] = useState(null);
  const navigate = useNavigate();
  const { currentUser, _currentUser, currentWorkspace, setCurrentWorkspace, handleSelectCurrentWorkspace } =
    useContext(User);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [type, setType] = useState('all');
  const { setState, state } = useTourContext();

  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({ search: searchTerm });

  useEffect(() => {
    const handler = setTimeout(() => setFilter((prev) => ({ ...prev, search: searchTerm })), 500);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  useEffect(() => {
    if (currentWorkspace) getUserProeprties();
  }, [filter, page, currentWorkspace, type]);

  const getUserProeprties = async () => {
    try {
      setIsLoading(true);
      const result = await AxiosRequest.get(
        `/property?page=${page}&limit=9&workSpace=${currentWorkspace?._id}&type=${type}&search=${filter.search}`
      );

      if (page === 1) setUserProperty(result.data.data.data);
      else setUserProperty((prevProperties) => [...prevProperties, ...result.data.data.data]);

      setTotalGenerations(result.data.data.totalCount);
      setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      setIsLoading(false);
    }
  };

  const handleReadMore = (data) => {
    // if (data?.socialDescription || data?.videoUrl) {
    navigate(`/property-details/${data._id}`);
    // }
  };


  useEffect(() => {
    if (currentUser && !currentUser?.tourTaken && state.stepIndex === 0) {
      setShowCancelModal(true);
    } else {
      setShowCancelModal(false);
    }
  }, [currentUser]);

  const handleTour = () => {
    setShowCancelModal(false);
    setState((prevState) => {
      return {
        ...prevState,
        run: true,
        tourActive: true,
      };
    });
    // setTimeout(() => {
    //   navigate("/my-generation")
    // }, 300)
  };

  const closeModal = async () => {
    await AxiosRequest.post('/user/tourCompleted');
    _currentUser();
    setShowCancelModal(false);
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div id="generated-content" className="pricing">
        {/* <BannerHeader heading="My Listings" id={undefined} /> */}
        <div className="mt-3 d-flex justify-content-between w-100">
          {userProperty.length === 0 && !isLoading && (
            <div className="plan-text fs30 w-100 text-start mb-3">Welcome to Restate Copilot</div>
          )}
          <div id="generate" className="generate_header">
            <div className="generate_header_filter w-100 justify-content-between">
              {/* <Nav
                className="customTab"
                variant="pills"
                activeKey={type}
                onSelect={(key) => {
                  setType(key);
                  setPage(1);
                }}
              >
                <Nav.Item className="NavItems1">
                  <Nav.Link className="navLink" eventKey="all">
                    <span className="me-1">
                      <AllPresentationIcon />
                    </span>
                    <span className="NavItem_text">All</span>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="NavItems2">
                  <Nav.Link className="navLink" eventKey="user">
                    <span className="me-1">
                      <UserIcon />
                    </span>
                    <span className="NavItem_text">Created by you</span>
                  </Nav.Link>
                </Nav.Item>
              </Nav> */}
              <div></div>

              <div className="searchInputContainer">
                <SearchIcon className="searchIcon" />
                <input
                  type="search"
                  className="searchInput"
                  placeholder="Search your files here using address"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div>
                <div className="d-flex">
                  <span className="me-3">
                    <div className='icon-bg'>
                    <SettingIcon />
                    </div>
                  </span>
                  <span>
                    <NotificationComponent />
                  </span>
                </div>
              </div>
            </div>
            {/* <GenerateButton /> */}
          </div>
        </div>
        <div className="dashboard">
          <div className="row">
            {userProperty.length > 0 ? (
              userProperty.map((data, index) => {
                return (
                  <div className="col-md-12 col-lg-6 col-xl-4  mb-3" key={index} onClick={() => handleReadMore(data)}>
                    <div className="mask-group-parent custom">
                      {data?.coverImage ? (
                        <div className="imageContainer2">
                          <img className="img-fluid cover" alt="" src={data?.coverImage} />
                        </div>
                      ) : (
                        <div className="imageContainer2">
                          <img className="img-fluid contain" alt="" src={PlaceholdeImage} />
                        </div>
                      )}
                      <div className="frame-parent-generation w-100">
                        <div className="d-flex flex-wrap justify-content-between align-items-start w-100">
                          <div className="d-flex flex-column align-items-start">
                            <div className="frame-wrapper-generation">
                              <div className="title-here-wrapper">
                                <div className="home title">{data.name}</div>
                              </div>
                            </div>
                            <div className="title-here-wrapper">
                              <div className="home date">
                                {data?.createdAt ? moment(data.createdAt).format('MMMM DD YYYY') : '-'}
                              </div>
                            </div>
                          </div>
                          <button className="btnNewPrimary sm">View More</button>
                          {/* <div className="button-icon">
                            <div className="login-parent">
                              <button className="login">View More</button>
                              <img className="vector-icon" alt="" src="/vector.svg" />
                            </div>
                          </div> */}
                        </div>
                        <div className="userArea d-flex align-items-center w-100">
                          <span className="profileImg">
                            <img src={data?.image_url || defaultAvtar} />
                          </span>
                          <div className="d-flex flex-column ms-2">
                            <span className="createdBy">Created by {data.userName || '-'}</span>
                            <span className="date">
                              {data.createdAt ? moment(data.createdAt).format('MMM DD, YYYY') : '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>
                {!isLoading && (
                  <div className="noProperty">
                    <div className="font20Blk text-center">No Property Found!</div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5 mb-5">
          {userProperty.length > 0 && userProperty.length < totalGenerations && (
            <div
              className="btnSecondary"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Load More
            </div>
          )}
        </div>
      </div>

      <Modal show={showCancelModal} backdrop="static" onHide={closeModal} className="modalCustomNew">
        <Modal.Header closeButton>Take a Tour</Modal.Header>
        <Modal.Body>Take a tour to understand the flow of the website.</Modal.Body>
        <Modal.Footer>
          <button className="btn btnBordered" onClick={closeModal}>
            Skip
          </button>
          <button className="btn btnNewPrimary" onClick={handleTour}>
            Let's take a tour
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Generation;
