import copy from "copy-to-clipboard";
import toast from "react-hot-toast";

export const copyText = (text) => {
  let plainText;

  try {
    plainText = JSON.parse(text).replace(/(\n\n)/g, '\n\n');
  } catch (error) {
    plainText = text.replace(/(\n\n)/g, '\n\n');
  }

  copy(plainText);
  toast.success("Description Copied.", { position: "bottom-center" });
};

export const musicArray = [
  {
    name: "Summer Walk",
    // url: "https://s3.us-east-2.amazonaws.com/airealtix/dev/profile/fd8047f8-7bf6-4bf7-a249-22ba4485027b.mpga",
    url: "https://ai-avengers-content.s3.us-east-1.amazonaws.com/dev/profile/225a6e6a-c771-46f5-9603-ab859513ba6b.mpga"
  },
  {
    name: "Silent Night",
    // url: "https://s3.us-east-2.amazonaws.com/airealtix/dev/profile/67577428-0c9f-414f-8785-ad52d48e9c54.mpga",
    url: "https://ai-avengers-content.s3.us-east-1.amazonaws.com/dev/profile/5f7b71d1-5d71-44d8-9154-428edd30293b.mpga"
  },
  {
    name: "Forest Lullaby",
    // url: "https://s3.us-east-2.amazonaws.com/airealtix/dev/profile/6364c6f5-b985-4f6f-91d4-2d28df847154.mpga",
    url: "https://ai-avengers-content.s3.us-east-1.amazonaws.com/dev/profile/2fe992d3-3d7a-4041-bc64-074d1dd24b48.mpga"
  },
  {
    name: "Inside You",
    // url: "https://s3.us-east-2.amazonaws.com/airealtix/dev/profile/12aef6fb-dfa7-4a38-bcd3-fd8f408d474c.mpga",
    url: "https://ai-avengers-content.s3.us-east-1.amazonaws.com/dev/profile/ca6886f6-20e8-4043-8278-7595ff15332e.mpga"
  },
  {
    name: "Leva Iternity",
    // url: "https://s3.us-east-2.amazonaws.com/airealtix/dev/profile/623129fb-8f83-4d50-bf7d-248259a6adfe.mpga",
    url: "https://ai-avengers-content.s3.us-east-1.amazonaws.com/dev/profile/96975d21-8c2d-470b-a620-0fd0b41fc356.mpga"
  },
];
