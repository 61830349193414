import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import uploadImage from "../../assets/images/upload-1.svg";
import ReactCrop from "react-image-crop";
import storeData from "./LinkedList";
import "../../assets/styles/ImageEdit.scss";
import "react-image-crop/dist/ReactCrop.css";
import { UploadPropertyIcon } from "../../shared/Icons";

const VideoDetails = (props) => {
  const handleDelete = (index) => {
    props.onRemoveImage(props.columnIndex, index);
  };
  return (
    <>
      <Modal
        // {...props}
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title
            id="contained-modal-title-vcenter "
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div className="flex" style={{ display: 'flex', alignItems: 'center' }}>
              <span style={{ whiteSpace: 'nowrap' }}>Upload Images</span>
              <div
                className="input-field2-parent10 "
                style={{ marginLeft: '50px' }}
                onClick={() =>
                  props.setIsShowCropImageModal({
                    show: false,
                    col: props.columnIndex,
                  })
                }
              >
                <label htmlFor="choose">
                  {/* <div className="" style={{ cursor: "pointer" }}>
                    <UploadPropertyIcon />
                  </div> */}
                </label>
              </div>
            </div>
            <div>
              <button className="btn btnBordered" style={{ marginRight: '5px' }} onClick={props.onHide}>
                Save
              </button>
              {/* <button className="btn btnPrimary" onClick={Cancel}>
                            Cancel
                        </button> */}
            </div>
          </Modal.Title>
        </Modal.Header>
        {props.data.images.length > 0 && (
          <Modal.Body>
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}
            >
              {props &&
                props.data.images &&
                props.data.images.map((file, index) => (
                  <div className="videoDetails_Modal">
                    <img
                      key={index}
                      src={props.id ? (file?.image_url ? file.image_url : file) : file?.image_url}
                      alt={`selected-${index}`}
                      name={`propertyImages[${props.columnIndex}].images[${index}]`}
                      style={{
                        margin: '10px',
                        objectFit: 'cover',
                        aspectRatio: '1/1',
                        width: '150px',
                      }}
                      className="rounded"
                    />
                    <div className="videoDetails_Image_cancel" onClick={() => handleDelete(index)}>
                      {' '}
                      X{' '}
                    </div>
                  </div>
                ))}
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default VideoDetails;
